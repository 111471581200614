<template>
  <div>
    <div class="botoes-acoes">
      <b-button
        :disabled="itemsSelecionados.length == 0"
        @click="clickExcluir"
        variant="danger"
        :class="classBotaoExcluir"
      >
        <feather type="trash" class="icones-btn"></feather>
        {{ $t("GERAL.EXCLUIR") }}</b-button
      >
    </div>
    <div class="table-notas-promissorias table-scroll">
      <Table
        ref="table-notas-promissorias"
        permission="NotasPromissorias"
        :camposData="tabela.campos"
        :noPagination="true"
        :dataValues="data"
        @duplicar="(item) => tabelaDuplicar(item)"
        @abrirEditar="(id) => tabelaAbrirEditar(id)"
        @confirmEdit="
          (dataC, index, removerIndex) =>
            tabelaConfirmarEdicao(dataC, index, undefined, removerIndex)
        "
        @confirmUpdate="
          (item, index, removerIndex) =>
            tabelaConfirmarAtualizacao(item, index, removerIndex)
        "
        @selectItems="
          (id) => {
            tabelaSelecionarItems(id);
          }
        "
        @excluir="(id) => tabelaExcluir(id)"
        @buscarPorCNPJ="
          (cnpj, campo, index) => tabelaBuscarPorCNPJ(cnpj, campo, index)
        "
        :disableHeader="true"
      />
    </div>
    <b-row class="operacoes-infos res-inputs">
      <input-text
        name="text1"
        :value="formatarValor(this.totalFace)"
        class="operacoes-text"
        :readonly="true"
        :label="$t('OPERACOES.totalFace')"
      />
      <input-text
        name="text2"
        :value="formatarValor(this.totalLiquido)"
        class="operacoes-text"
        :readonly="true"
        :label="$t('OPERACOES.totalLiquido')"
      />
      <div
        v-if="habilitarLimpar"
        v-on:click="clickLimparCache"
        class="btn-operacoes normal"
      >
        {{ $t("OPERACOES.LIMPAR_OPERACOES") }}
      </div>
      <div
        v-if="habilitarOperacao"
        @click="clickEnviarOperacaoSelecionarFavorecido()"
        :class="classSelecionarFavorecido"
      >
        {{ $t("OPERACOES.VALIDAR_DADOS_BANCARIOS") }}
      </div>
    </b-row>

    <Favorecidos
      ref="favorecido"
      @funcaoEnviar="
        (favorecido, arquivos, observacao) =>
          enviarOperacao(favorecido, arquivos, observacao)
      "
      title="Selecione o Favorecido da Operação"
    />
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { NotaPromissoria } from "@/common/models/NotaPromissoria";
import Table from "@/components/table/Table";
import InputText from "@/components/inputs/InputText";
import Favorecidos from "./Favorecidos";
import NotasPromissoriasService from "@/common/services/notas-promissorias/notas-promissorias.service";
import OperacoesService from "@/common/services/operacoes/operacoes.service";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import Swal from "sweetalert2";
import Util from "../utils/helper";
import helpers from "@/common/utils/helpers";

export default {
  name: "NotasPromissorias",
  components: {
    Table,
    InputText,
    Favorecidos,
    // Modal,
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.OPERACOES") },
      { titulo: this.$t("OPERACOES.NOVA_OPERACAO") },
      { titulo: this.$t("OPERACOES.NOTAS_PROMISSORIAS") },
    ]);

    this.listarTitulos();
  },
  computed: {
    classSelecionarFavorecido() {
      return this.data.length <= 0
        ? "btn-operacoes orange btn-desativado"
        : "btn-operacoes orange";
    },
    classBotaoEditar() {
      return this.itemsSelecionados.length < 2
        ? "desabilitado btn-acao"
        : "btn-acao";
    },
    classBotaoExcluir() {
      return this.itemsSelecionados.length == 0
        ? "desabilitado btn-acao"
        : "btn-acao";
    },
  },
  data() {
    return {
      data: [],
      favorecido: undefined,
      habilitarLimpar: helpers.validarAcesso("NotasPromissorias").deletar,
      habilitarOperacao: helpers.validarAcesso("Operacao").criar,
      campoSelecionado: undefined,
      valorCampoSelecionado: undefined,
      itemsSelecionados: [],
      totalFace: 0,
      totalLiquido: 0,
      tabela: {
        quantidadeLinhas: 0,
        paginaAtual: 0,
        porPagina: 100,
        campos: [
          {
            key: "item",
            label: "",
            thClass: "item-table",
          },
          {
            key: "numero",
            label: "",
            thClass: "item-table",
          },
          {
            key: "acoes",
            label: "",
            tdClass: "acoes acoes-index",
            thClass: "acao-table",
            stickyColumn: true,
          },
          {
            key: "numeroTitulo",
            label: "Numero do Titulo *",
            editavel: true,
            editabelIndex: 1,
            required: true,
          },
          {
            key: "valorNominal",
            label: "Valor Face *",
            editavel: true,
            editabelIndex: 1,
            thClass: "valor-table",
            required: true,
          },
          {
            key: "valorDesconto",
            label: "Valor Desconto",
            editavel: true,
            thClass: "valor-table",
            editabelIndex: 1,
            required: false,
          },
          {
            key: "dataEmissao",
            label: "Data da Emissão *",
            editavel: true,
            editabelIndex: 1,
            required: true,
          },
          {
            key: "dataVencimento",
            label: "Data de Vencimento *",
            editavel: true,
            editabelIndex: 1,
            required: true,
          },
          {
            key: "cpfCnpjSacado",
            label: "CPF/CNPJ do Sacado *",
            editavel: true,
            editabelIndex: 2,
            required: true,
            tdClass: "cpf-cnpj-sacado",
          },
          {
            key: "nomeSacado",
            label: "Nome do Sacado *",
            editavel: true,
            editabelIndex: 2,
            required: true,
          },
          {
            key: "email",
            label: "Email",
            editavel: true,
            editabelIndex: 1,
          },
          {
            key: "telefoneSacado",
            label: "Telefone do Sacado",
            thClass: "telefone-sacado",
            editavel: true,
            editabelIndex: 1,
          },
          {
            key: "endereco",
            label: "Endereço *",
            editavel: true,
            editabelIndex: 2,
            required: true,
            thClass: "w-170",
          },
          {
            key: "cep",
            label: "CEP *",
            editavel: true,
            editabelIndex: 2,
            required: true,
            thClass: "cep-table",
          },
          {
            key: "numeroEndereco",
            label: "Numero do Endereço *",
            editavel: true,
            editabelIndex: 2,
            required: true,
          },
          {
            key: "bairro",
            label: "Bairro *",
            editavel: true,
            editabelIndex: 2,
            required: true,
            thClass: "w-170",
          },
          {
            key: "cidade",
            label: "Cidade *",
            editavel: true,
            editabelIndex: 2,
            required: true,
            thClass: "w-170",
          },
          {
            key: "uf",
            label: "UF *",
            editavel: true,
            editabelIndex: 2,
            thClass: "uf-table",
            required: true,
          },
        ],
      },
    };
  },
  methods: {
    listarTitulos() {
      this.totalFace = 0;
      this.totalLiquido = 0;
      this.itemsSelecionados = [];

      this.$store.dispatch(START_LOADING);
      NotasPromissoriasService.listarTitulosNotasPromissorias(
        this.tabela.paginaAtual,
        this.tabela.porPagina
      )
        .then((retornoService) => {
          let valorRetornado = retornoService.retornoFormatado;
          this.tabela.quantidadeLinhas =
            retornoService.paginacao.totalDeElementos;

          this.totalFace = retornoService.valorTotalFace;
          this.totalLiquido = retornoService.valorTotalLiquido;

          let itemEmBranco = new NotaPromissoria();
          this.data = [...valorRetornado, { ...itemEmBranco }];
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    enviarOperacao(favorecido, arquivos, observacao) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          let operationSend = true;

          if (operationSend) {
            Swal.fire({
              showConfirmButton: false,
              customClass: "sweet-enviando-operacao",
              title:
                "<div class='titulo-enviando-operacao'>Enviando Dados</div>",
              html:
                "<div class='desc-enviando-operacao'>Este passo pode demorar um pouco. Por favor, aguarde enquanto guardamos suas informações</div>" +
                "<div class='linePreloader'></div>",
            });

            let formSave = {
              favorecido: favorecido || this.favorecido,
              arquivos,
              observacao,
            };

            NotasPromissoriasService.enviarOperacao(formSave)
              .then((result) => {
                if (result.data.success) {
                  Swal.fire({
                    title: "Sucesso!",
                    text:
                      "A ação foi executada com sucesso!" +
                      " (Borderô: " +
                      result.data.data.bordero +
                      ")",
                    icon: "success",
                    confirmButtonText: this.$t("GERAL.OK"),
                  });
                  let itemEmBranco = new NotaPromissoria();
                  this.data = [{ ...itemEmBranco }];
                  this.$bvModal.hide("modal-favorecidos");

                  this.totalFace = 0;
                  this.totalLiquido = 0;
                } else {
                  Swal.close();
                  this.$bvModal.hide("modal-favorecidos");
                }
              })
              .catch(() => {
                Swal.close();
                this.$bvModal.hide("modal-favorecidos");
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        }
      });
    },
    limparCampoSelecionado() {
      this.campoSelecionado = undefined;
      this.valorCampoSelecionado = undefined;
    },
    formatarTelefoneEdicao(telefone) {
      let telefoneNumeros = telefone.replace(/[^0-9]/g, "");
      let ddd = telefoneNumeros.toString().substring(0, 2);
      let celular = telefoneNumeros.toString().substring(2);

      return ddd + "-" + celular;
    },
    formatarValor(valor) {
      return helpers.formatarValor(valor);
    },
    verificarSeSalvou() {
      let sendData = this.data;
      let notSave = false;

      for (let i = 0; i < sendData.length; i++) {
        for (const key in sendData[i]) {
          if (Object.hasOwnProperty.call(sendData[i], key)) {
            if (
              sendData[i]["editavel"] == true &&
              key != "new" &&
              key != "tipo" &&
              key != "erros" &&
              key != "valido" &&
              key != "existing" &&
              key &&
              sendData[i][key][key]
            ) {
              notSave = true;
            }
          }
        }
      }

      return notSave;
    },
    validarEdicaoEmMassa() {
      let arValidation = [];
      arValidation.push(this.$refs.valorCampoSelecionado.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    clickExcluir() {
      if (
        !this.itemsSelecionados ||
        (this.itemsSelecionados && this.itemsSelecionados.length <= 0)
      ) {
        Swal.fire({
          title: this.$t("GERAL.ERRO"),
          text: this.$t("OPERACOES.ITEM_MSG"),
          icon: "error",
          confirmButtonText: this.$t("GERAL.OK"),
        }).then(() => {});
      } else {
        Confirmacao.SimNao(
          this,
          null,
          this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
        ).then(async (result) => {
          if (!result.value) return;

          this.$store.dispatch(START_LOADING);
          NotasPromissoriasService.excluirVarios(this.itemsSelecionados)
            .then(() => {
              this.itemsSelecionados = [];
              this.listarTitulos();
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        });
      }
    },
    clickLimparCache() {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          NotasPromissoriasService.limparCache({})
            .then((resutl) => {
              if (resutl.data.success) {
                this.data = [];
                this.totalFace = 0;
                this.totalLiquido = 0;

                this.listarTitulos();
              }
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    clickEnviarOperacaoSelecionarFavorecido() {
      if (Util.verifyData(this.data, true) == false) {
        this.$toast.error(
          "Favor corrigir os erros apontados antes de selecionar favorecidos",
          {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        return;
      }

      if (this.verificarSeSalvou()) {
        this.$toast.error(
          "Por favor verifique se todas as informações foram salvas!",
          {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        return;
      }

      this.$refs.favorecido.inicializar();
      this.$bvModal.show("modal-favorecidos");
    },
    tabelaConfirmarEdicao(values, index, enviar) {
      // tabelaConfirmarEdicao(values, index) {
      values = [...values];
      values[index].valorNominal.valorNominal = helpers.removerMascaraValor(
        values[index].valorNominal.valorNominal
      );
      values[index].valorDesconto.valorDesconto = helpers.removerMascaraValor(
        values[index].valorDesconto.valorDesconto
      );

      this.$store.dispatch(START_LOADING);
      NotasPromissoriasService.salvar(values[index])
        .then(async (retorno) => {
          if (Util.verifyData(Util.formatData(retorno)) == false) {
            let item = Util.formatData(retorno)[0];

            item.editavel = true;
            this.$set(this.data, index, item);

            return false;
          }

          if (enviar) {
            this.enviarOperacao(false);
            return false;
          }

          this.listarTitulos();

          // if (!noList) {
          //   deletIndex();
          // }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    tabelaConfirmarAtualizacao(item, index, deletIndex) {
      // tabelaConfirmarAtualizacao(item) {
      let itemSave = helpers.clonarObjeto(item);
      itemSave.editavel = false;

      this.totalFace = 0;
      this.totalLiquido = 0;
      itemSave.valorNominal.valorNominal = helpers.removerMascaraValor(
        itemSave.valorNominal.valorNominal
      );
      itemSave.valorDesconto.valorDesconto = helpers.removerMascaraValor(
        itemSave.valorDesconto.valorDesconto
      );

      this.$store.dispatch(START_LOADING);
      NotasPromissoriasService.salvar(itemSave)
        .then((result) => {
          if (Util.verifyData(Util.formatData(result)) == false) {
            let titulo = Util.formatData(result)[0];
            titulo.editavel = true;
            titulo.habilitarSalvar = true;
            this.$set(this.data, index, titulo);
            return;
          }

          let titulo = Util.formatData(result)[0];
          titulo.editavel = true;
          this.$set(this.data, index, titulo);

          deletIndex();
          this.listarTitulos();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    tabelaDuplicar(item) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].editavel) {
          this.$delete(this.data, i);
        }
      }
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          if (
            key != "idItem" &&
            key != "editavel" &&
            key != "existing" &&
            key != "id" &&
            key != "new" &&
            key != "erros" &&
            key != "tipoTitulo" &&
            key != "titulos" &&
            key != "obrigatorio" &&
            key != "tipo" &&
            key != "habilitarSalvar" &&
            key != "valido" &&
            key != "flagEditado"
          ) {
            item[key]["editavel"] = true;
            item[key]["tipoTitulo"] = undefined;
          }
        }
      }

      let retItem = JSON.parse(JSON.stringify(item));
      this.data.push(retItem);
    },
    tabelaAbrirEditar(id) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].idItem == id) {
          // console.log("this.data[i]", this.data[i]);
          this.$set(this.data[i], "editavel", true);
          this.$set(this.data[i], "existing", true);
        }
      }
    },
    tabelaSelecionarItems(id) {
      if (this.itemsSelecionados.includes(id)) {
        for (let i = 0; i < this.itemsSelecionados.length; i++) {
          if (this.itemsSelecionados[i] == id) {
            this.$delete(this.itemsSelecionados, i);
          }
        }
      } else {
        this.itemsSelecionados.push(id);
      }
    },
    tabelaExcluir(id) {
      // console.log(id);
      this.$store.dispatch(START_LOADING);
      NotasPromissoriasService.excluir(id)
        .then(() => {
          this.NotasPromissoriasService();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    tabelaPaginar(pagina, porPagina) {
      this.paginacao.paginaAtual = pagina - 1;
      this.paginacao.porPagina = porPagina;

      this.listarTitulos();
    },
    tabelaBuscarPorCNPJ(cnpj, campo, index) {
      if (!cnpj || cnpj == null || cnpj == "") return;
      this.$store.dispatch(START_LOADING);
      OperacoesService.buscarDadosPorCNPJ(cnpj.replace(/[^\d]+/g, ""))
        .then((resultado) => {
          this.$set(this.data, index, { ...this.data[index], ...resultado[0] });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style>
.table-notas-promissorias {
  margin-top: 20px;
  min-height: 480px;
  height: 480px;
}
.botoes-acoes {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 260px;
}
.btn-editar {
  width: 49%;
}
.inputs-editar {
  justify-content: space-between;
  align-content: space-between;
  height: 100px;
}
.campos {
  min-width: 300px;
  max-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.desabilitado {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-acao {
  display: flex;
  align-items: center;
}
.icones-btn {
  width: 20px;
  margin: 3px;
}
</style>