<template>
  <b-carousel
    v-if="comunicados.length > 0"
    v-model="slide"
    :interval="4000"
    controls
    indicators
    background="#ababab"
    style="text-shadow: 1px 1px 2px #333; width: 100%; height: 100%; border-radius: 4px"
  >
    <b-carousel-slide
      style="width: 100%"
      v-for="(comunicado, i) in comunicados"
      :key="i"
      :img-src="comunicado.fotoBase64"
    >
      <template #img>
        <a :href="comunicado.link" target="_blank">
          <img
            @click="() => criarLogClick(comunicado.titulo)"
            style="object-fit: cover; width: 100%; height: 100%"
            :src="comunicado.fotoBase64"
            :alt="comunicado.titulo"
          />
        </a>
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import ComunicadoService from "@/common/services/comunicado/comunicado.service";
import LogsService from "@/common/services/logs/logs.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";

export default {
  name: "Mensageria",
  data() {
    return {
      comunicados: [],
      slide: 0,
    };
  },
  mounted() {
    this.listarComunicado();
    this.verificarComunicados();
  },
  methods: {
    criarLogClick(titulo) {
      this.$store.dispatch(START_LOADING);
      LogsService.gerarLog({
        funcao: "Comunicado",
        localAcesso: titulo,
      }).finally(() => {
        this.$store.dispatch(STOP_LOADING);
      });
    },
    listarComunicado() {
      this.$store.dispatch(START_LOADING);
      ComunicadoService.exibicao()
        .then((result) => {
          this.comunicados = result.data.data.comunicados;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.$emit("carregou");
        });
    },
    verificarComunicados() {
      this.$emit("hasComunicados", this.comunicados);
    },
  },
  watch: {
    comunicados(value) {
      if (value) this.verificarComunicados();
    },
  },
};
</script>

<style>
.mensageria-container {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.card-body {
  transition: 0.5s;
  cursor: pointer;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 4px;
}
</style>
