import ApiService from "@/common/api/api.service";
import helper from "@/common/utils/helpers.js";

const TitulosNotasService = {


  formatarTitulo(retorno) {
    let retornoFormatado = [];
    let valorRetornado = retorno.data.data.itens[0].titulos;

    for (let i = 0; i < valorRetornado.length; i++) {
      let ddd = valorRetornado[i].telefoneSacado.ddd.ddd;
      valorRetornado[i].idItem = valorRetornado[i].id;
      delete valorRetornado[i].id;
      delete valorRetornado[i].cmc7;
      valorRetornado[i].telefoneSacado.numero.valido = valorRetornado[i].telefoneSacado.ddd.valido == false ? false: valorRetornado[i].telefoneSacado.numero.valido;
      valorRetornado[i].telefoneSacado = valorRetornado[i].telefoneSacado.numero;
      valorRetornado[i].telefoneSacado.telefoneSacado = (ddd || "") + "" + (valorRetornado[i].telefoneSacado.numero || "");
      delete valorRetornado[i].telefoneSacado.numero;


      let enderecoSacado = valorRetornado[i].enderecoSacado;
      enderecoSacado.numeroEndereco = enderecoSacado.numero;
      enderecoSacado.numeroEndereco.numeroEndereco =
      enderecoSacado.numero.numero;

      delete enderecoSacado.numeroEndereco.numero;
      delete valorRetornado[i].enderecoSacado;
      delete enderecoSacado.numero;

      valorRetornado[i].habilitarSalvar =
        helper.validarAcesso("Titulos").editar;

      retornoFormatado.push({ ...valorRetornado[i], ...enderecoSacado });
    }

    for (let i = 0; i < retornoFormatado.length; i++) {
      for (const key in retornoFormatado[i]) {
        if (Object.hasOwnProperty.call(retornoFormatado[i], key)) {
          for (const x in retornoFormatado[i][key]) {
            if (Object.hasOwnProperty.call(retornoFormatado[i][key], x)) {
              if (x == "valido" && retornoFormatado[i][key].valido == false) {
                retornoFormatado[i].valido = false;
              }
            }
          }
        }
      }
    }

    return retornoFormatado;
  },
  formatarEnvioTitulo(item) {
    let phone = undefined;
    let ddd = undefined;

    item = helper.clonarObjeto(item);

    if (
      item &&
      item["telefoneSacado"] &&
      item["telefoneSacado"]["telefoneSacado"]
    ) {
      let telefone = item["telefoneSacado"]["telefoneSacado"].replace(
        /[^0-9]/g,
        ""
      );
      ddd = telefone.substring(0, 2);
      phone = telefone.substring(2);
    }

    let itemFromSave = {
      cpfCnpjSacado: item["cpfCnpjSacado"]["cpfCnpjSacado"]
        ? item["cpfCnpjSacado"]["cpfCnpjSacado"].replace(/\D/g, "")
        : undefined,
      nomeSacado: item["nomeSacado"]["nomeSacado"],
      enderecoSacado: {
        endereco: item["endereco"]["endereco"],
        numero: item["numeroEndereco"]["numeroEndereco"],
        bairro: item["bairro"]["bairro"],
        cidade: item["cidade"]["cidade"],
        uf: item["uf"]["uf"],
        cep: item["cep"]["cep"],
        email: item && item["email"] ? item["email"]["email"] : '',
      },
      telefoneSacado: {
        ddd: ddd == null || !ddd ? undefined : parseInt(ddd),
        numero: phone && parseInt(phone.replace("-", "")),
      },
      numeroTitulo: item["numeroTitulo"]["numeroTitulo"],
      nomeArquivo:
        item["nomeArquivo"] && item["nomeArquivo"]["nomeArquivo"]
          ? item["nomeArquivo"]["nomeArquivo"]
          : undefined,
      dataEmissao: item["dataEmissao"]["dataEmissao"],
      dataVencimento:
        item["dataVencimento"]["dataVencimento"] != null
          ? item["dataVencimento"]["dataVencimento"]
          : undefined,
      valorNominal: item["valorNominal"]["valorNominal"]
        ? parseFloat(item["valorNominal"]["valorNominal"])
        : "0",
      valorDesconto: item["valorDesconto"]["valorDesconto"]
        ? parseFloat(item["valorDesconto"]["valorDesconto"])
        : "0",
      chaveNfe:
        item["chaveNfe"]["chaveNfe"] && item["chaveNfe"]["chaveNfe"] != ""
          ? item["chaveNfe"]["chaveNfe"]
          : undefined,
      id: item.idItem ? item.idItem : undefined,
      tipoTitulo:
        item.tipoTitulo &&
        item.tipoTitulo.tipoTitulo &&
        item.tipoTitulo.tipoTitulo != "Manual"
          ? item.tipoTitulo.tipoTitulo
          : undefined,
    };
    return itemFromSave;
  },
  async importarXml(form, file) {
    let retorno = await ApiService.post(
      `titulo/importar-xml`,
      { ...form },
      { noToast: true },
      file
    );

    // let retornoFormatado = this.formatarTitulo(retorno);

    return retorno;
  },
  async listar_titulos(pagina = 0, porPagina = 0) {
    let retorno = await ApiService.get(`titulo?NumeroDaPagina=${pagina}&TamanhoDaPagina=${porPagina}`);

    // let valorNominal = 0;
    // let valorDesconto = 0;

    let retornoFormatado = this.formatarTitulo(retorno);
    let verificarTituloDuplicado = [];
    let titulosDuplicados = false;

    for (let i = 0; i < retornoFormatado.length; i++) {
      // valorNominal += parseFloat(
      //   retornoFormatado[i]["valorNominal"]["valorNominal"]
      // );
      // valorDesconto += parseFloat(
      //   retornoFormatado[i]["valorDesconto"]["valorDesconto"]
      // );

      verificarTituloDuplicado.push(
        retornoFormatado[i]["numeroTitulo"]["numeroTitulo"]
      );
    }

    let duplicados = verificarTituloDuplicado.filter(function (
      value,
      index,
      self
    ) {
      return self.indexOf(value) !== index;
    });

    if (duplicados && duplicados.length > 0) {
      titulosDuplicados = true;
    }

    let retornoService = {
      retornoFormatado,
      // valorNominal,
      // valorDesconto,
      titulosDuplicados,
      paginacao : retorno.data.data.paginacao,
      manual : retorno.data.data.itens[0].manual,
      valorTotalFace : retorno.data.data.itens[0].valorTotalFace,
      valorTotalLiquido : retorno.data.data.itens[0].valorTotalLiquido
    };

    return retornoService;
  },
  enviarOperacao(form, file) {
    return ApiService.post(`titulo/enviar`, { ...form }, {}, file);
  },
  limparCache(form, file) {
    return ApiService.post(`titulo/limpar-cache`, { ...form }, {}, file);
  },
  excluir(id) {
    return ApiService.delete(`titulo/?id=${id}`);
  },
  excluirVarios(form) {
    return ApiService.delete(`titulo/remover-varios`,form);
  },
  salvar(form) {
    let resultado = [];
    let envioFormatado = this.formatarEnvioTitulo(form);

    if (!envioFormatado.id)
      resultado = ApiService.post(`titulo/`, { ...envioFormatado });
    else resultado = ApiService.put(`titulo/`, { ...envioFormatado });
    return resultado;
  },

  EnviarEdicaoEmMassa(form) {
    return ApiService.put(`/Titulo/update-varios`, { ...form });
  },
};

export default TitulosNotasService;
