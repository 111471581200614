<template>
  <div class="valorem-table">
    <b-table
      :sticky-header="true"
      :no-border-collapse="true"
      hover
      head-variant="light"
      :items="items"
      :keyword="text"
      :fields="camposItems"
      responsive="sm"
    >
      <template v-slot:cell(item)="item">
        <input-checkbox
          v-if="item.item.idItem && !item.item.new"
          @onChange="
            () => selectItem(item.item.idItem || item.item.id, item.item, index)
          "
          :value="item.item.active ? true : false"
        />
      </template>
      <template v-slot:head(item)>
        <input-checkbox @onChange="selecionarTudo" :value="todosAtivado" />
      </template>
      <template style="width: 50px" class="teste-td" v-slot:cell(numero)="item">
        <div
          v-if="item.index + 1 < items.length"
          :class="
            item.item.valido == false
              ? 'color-red'
              : item.item.flagEditado
              ? 'color-blue'
              : ''
          "
        >
          {{ ((paginaAtual - 1) * porPagina) + item.index + 1 }}
        </div>
      </template>
      <template v-slot:cell(acoes)="item">
        <div
          v-if="
            selectedItemIndex == undefined || selectedItemIndex == item.index
          "
        >
          <div
            class="check"
            v-if="item.item.editavel && item.item.habilitarSalvar"
          >
            <b-button
              variant="primary"
              v-on:click.prevent="confirmEdit(item.item, item.index)"
              >Salvar</b-button
            >
          </div>
          <div v-else-if="!item.item.editavel">
            <b-button
              @click="
                abrirEditar(
                  item.item.idItem || item.item.id,
                  item.item,
                  item.index
                )
              "
              variant="outline-secondary"
              >{{ $t("CAMPOS_TABELA_OPERACOES.EDITAR") }}</b-button
            >
            <b-dropdown
              dropright
              class="dropdown-acao"
              variant="outline-default"
            >
              <b-dropdown-item v-if="criar" @click="duplicar(item.item)">{{
                $t("GERAL.DUPLICAR")
              }}</b-dropdown-item>
              <b-dropdown-item v-if="deletar" @click="excluir(item.item.idItem)"
                >{{ $t("GERAL.EXCLUIR") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:cell()="{ value, item, index, field: { key } }">
        <template v-if="!item.editavel || !value.editavel">
          <div
            class="div-item-tabela"
            v-on:dblclick="abrirEditar(item.idItem || item.id, item, index)"
          >
            {{ campoTexto(value[key], value.tipo) }}

            <feather
              v-if="value && value.valido == false"
              type="alert-circle"
              class="feather-sm icone-alerta"
            >
            </feather>
          </div>
        </template>
        <template v-else-if="item.editavel && value.editavel">
          <input-text
            :somenteNumeros="key == 'cmc7'"
            v-if="value.tipo == 'TEXT'"
            :stateCustom="value.valido"
            :minLength="minimoTamanho(key)"
            :maxLength="maximoTamanho(key)"
            :disabled="desabilitarCampo(key)"
            :required="value.obrigatorio"
            :ref="key + '-' + index"
            :generalRef="key"
            :refInput="key + '-' + index"
            :type="key"
            :isEstado="key == 'uf'"
            ocultarLabel
            :label="key"
            class="form-editavel"
            maxLengthMessage="O campo deve ter no máximo 10 caracteres"
            @change="alterarTexto(items, key, index)"
            @keydown.enter.native="
              (event) => salvarAoDarEnter(event, item, index)
            "
            @keydown.down.native="trocarLinhaParaBaixo(key, index)"
            @keydown.up.native="trocarLinhaParaCima(key, index)"
            v-model="value[key]"
            :style="tamanhoCampo(key)"
          />

          <input-text
            hideFeedback
            no-label
            :required="value.obrigatorio"
            :propsError="value.valido"
            :ref="key + '-' + index"
            :generalRef="key"
            @change="habilitarSalvar(index, key)"
            v-mask="['(##)####-####', '#####-####', '(##)#####-####']"
            class="form-editavel"
            v-else-if="value.tipo == 'PHONE'"
            @keydown.down.native="trocarLinhaParaBaixo(key, index)"
            @keydown.up.native="trocarLinhaParaCima(key, index)"
            v-model="value[key]"
          />

          <template v-else-if="value.tipo == 'CNPJ'">
            <div class="campo-cnpj">
              <input-text
                hideFeedback
                :minLength="10"
                :required="value.obrigatorio"
                :ref="key + '-' + index"
                :generalRef="key"
                isDocument
                no-label
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                class="form-editavel"
                @keydown.down.native="trocarLinhaParaBaixo(key, index)"
                @keydown.up.native="trocarLinhaParaCima(key, index)"
                :stateCustom="value.valido"
                @change="habilitarSalvar(index, key)"
                v-model="value[key]"
                @tab="buscarPorCNPJ(value[key], key, index)"
              />
              <feather
                v-if="value[key].length >= 10"
                v-b-tooltip="$t('GERAL.BUSCAR_INFO_DOCUMENTO')"
                class="btn-buscar-por-cnpj"
                @click="buscarPorCNPJ(value[key], key, index)"
                type="search"
              ></feather>
            </div>
          </template>

          <input-text
            hideFeedback
            :required="value.obrigatorio"
            :propsError="value.valido"
            :minLength="9"
            :ref="'cep-' + index"
            generalRef="cep"
            v-mask="'#####-###'"
            class="form-editavel"
            no-label
            v-else-if="value.tipo == 'CEP'"
            @keydown.down.native="trocarLinhaParaBaixo(key, index)"
            @keydown.up.native="trocarLinhaParaCima(key, index)"
            @change="habilitarSalvar(index, key)"
            v-model="value[key]"
          />

          <input-currency
            v-model="value[key]"
            hideFeedback
            :minLength="minimoTamanho(key)"
            :required="value.obrigatorio"
            :ref="key + '-' + index"
            :nullable="!value.valido"
            :generalRef="key"
            @change="
              (e) => {
                habilitarSalvar(index, key);
                changeValue(e, index, key);
              }
            "
            @keydown.down.native="trocarLinhaParaBaixo(key, index)"
            @keydown.up.native="trocarLinhaParaCima(key, index)"
            v-else-if="value.tipo == 'VALOR'"
            style="max-width: 127px"
            class="form-editavel"
          />

          <input-date
            :propsError="value.valido"
            :minLength="9"
            hideFeedback
            :required="value.obrigatorio"
            :ref="key + '-' + index"
            :generalRef="key"
            :id="key"
            @keydown.down.native="trocarLinhaParaBaixo(key, index)"
            @keydown.up.native="trocarLinhaParaCima(key, index)"
            class="form-editavel"
            style="width: 170px"
            v-else-if="value.tipo == 'DATE'"
            v-model="value[key]"
            @change="habilitarSalvar(index, key)"
            no-label
          />
        </template>
      </template>
    </b-table>
    <div class="d-flex align-items-center justify-content-between">
      <div class="linha-legenda">
        <div>
          (Linha {{ selectedItemIndex + 1 || 0 }} de {{ items.length - 1 }})
        </div>
        <div class="legenda">
          <div class="legenda-erro" />
          <div>Linha com Erro</div>
        </div>
        <div class="legenda">
          <div class="legenda-editado" />
          <div>Editado</div>
        </div>
        <div class="legenda">
          <div class="legenda-ok" />
          <div>Ok</div>
        </div>
      </div>
      <div v-if="paginado" class="d-flex align-items-center justify-content-end">
        <h6 style="flex:none;" class="mr-2 mt-2">Por pagina</h6>
        <b-form-select
          v-model="porPagina"
          :options="opcoes.paginas"
          @change="setPorPagina"
          class="mr-2"
        />
        <b-pagination
          class="mt-0"
          @change="setPage"
          v-model="paginaAtual"
          :total-rows="totalLinhas"
          :per-page="porPagina"
        />
      </div>
		</div>
    
    
  </div>
</template>

<script>
import helpers from "@/common/utils/helpers";
import InputText from "@/components/inputs/InputText";
import InputCurrency from "@/components/inputs/InputCurrency";
import InputCheckbox from "@/components/inputs/InputCheckbox";
import InputDate from "@/components/inputs/InputDate";
import Confirmacao from "@/components/confirmacao/Confirmacao";
export default {
  name: "Table",
  components: {
    InputCurrency,
    InputCheckbox,
    InputDate,
    InputText,
  },
  props: {
    camposData: { type: Array },
    dataValues: { type: Array },
    permission: { },
    totalLinhas : {
      default : 0
    },
    paginado : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      clickEdit: false,
      todosAtivado: false,
      selectedItemIndex: undefined,
      text: "",
      deletar: this.permission
        ? helpers.validarAcesso(this.permission).deletar
        : true,
      editar: this.permission
        ? helpers.validarAcesso(this.permission).editar
        : true,
      criar: this.permission
        ? helpers.validarAcesso(this.permission).criar
        : true,
      opcoes : {
        paginas : [10,25,50,100,200,300,500]
      },
      porPagina : 100,
      paginaAtual : 1
    };
  },
  methods: {
    setPorPagina : function(valor){
      this.$emit('paginar',1, valor);
      this.paginaAtual = 1;
    },
    setPage : function(valor){
      this.$emit('paginar',valor, this.porPagina);
    },
    selecionarTudo() {
      if (this.todosAtivado == false) {
        this.todosAtivado = true;
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].idItem)
            this.selectItem(this.items[i].idItem, this.items[i]);
        }
      } else {
        this.todosAtivado = false;
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].idItem)
            this.selectItem(this.items[i].idItem, this.items[i]);
        }
      }
    },
    changeValue(e, index, key) {
      if (
        !this.items[index][key][key] ||
        this.converteMoedaFloat(this.items[index][key][key]) <= 0
      ) {
        this.items[index][key].valido = false;
      }
    },
    converteMoedaFloat(valor) {
      if (valor.indexOf("R$") <= -1) {
        valor = "R$ " + valor;
        // console.log("valor", valor);
      }

      if (!valor) return;
      let splitValor = valor.split("$");
      valor = splitValor[1];
      if (valor === "") {
        valor = 0;
      } else {
        valor = valor.replace(".", "");
        valor = valor.replace(",", ".");
        valor = parseFloat(valor);
      }

      // console.log("valoraa", valor);
      return valor;
    },
    buscarPorCNPJ(cnpj, campo, index) {
      if (campo === "cpfCnpjSacado") {
        this.$emit("buscarPorCNPJ", cnpj, campo, index);
      }
    },
    async trocarLinhaParaCima(key, _index) {
      if (this.dataValues.length - 1 === _index) return;
      if (this.validarFormulario(_index) == false) return;
      if (this.selectedItemIndex >= this.items.length - 1) return;
      if (this.selectedItemIndex > 0) {
        let index = this.selectedItemIndex;
        this.confirmEdit(this.items[_index], _index, true);
        this.items[index].editavel = false;
        index -= 1;
        this.items[index].editavel = true;
        this.items[index].habilitarSalvar = true;

        setTimeout(() => {
          this.$refs[
            key + "-" + (_index - 1)
          ].$el.children[0].children[0].children[0].focus();
        }, 100);

        this.selectedItemIndex = index;
      }
    },
    async trocarLinhaParaBaixo(key, _index) {
      if (this.dataValues.length - 1 === _index) return;
      if (this.validarFormulario(_index) == false) return;

      if (this.selectedItemIndex >= this.items.length - 1)
        return (this.selectedItemIndex = undefined);
      if (this.selectedItemIndex < this.items.length - 1) {
        let index = this.selectedItemIndex;
        this.confirmEdit(this.items[_index], _index, true);
        this.items[index].editavel = false;
        index += 1;

        this.items[index].editavel = true;
        if (index < this.items.length - 1) {
          this.items[index].habilitarSalvar = true;
        }

        if (index + 1 >= this.items.length) {
          this.selectedItemIndex = undefined;

          return;
        }

        setTimeout(() => {
          this.$refs[
            key + "-" + (_index + 1)
          ].$el.children[0].children[0].children[0].focus();
        }, 100);

        this.selectedItemIndex = index;
      }
    },
    campoTexto(valor, tipo) {
      if (tipo == "CNPJ" && valor) {
        if (valor.length > 11) {
          return helpers.maskCnpj(valor);
        } else {
          return helpers.maskCpf(valor);
        }
      }

      if (tipo == "PHONE" && valor) {
        return helpers.formatarTelefone(valor);
      }

      if (tipo == "VALOR" && valor) {
        return helpers.formatarValor(valor);
      }

      if (tipo == "DATE" && valor) {
        return helpers.formatarDataBr(valor);
      }

      if (tipo == "CEP" && valor) {
        return helpers.formatarCep(valor);
      }

      return valor;
    },
    validarFormulario(index, semMensagem) {
      let arValidation = [];
      let refs = this.$refs;
      let alert = false;
      for (const key in refs) {
        if (Object.hasOwnProperty.call(refs, key)) {
          if (refs[key] && key === refs[key].generalRef + "-" + index) {
            arValidation.push(refs[key].valid());
            if (refs[key].valid() == false) {
              alert = true;
            }
          }
        }
      }

      if (alert && !semMensagem) {
        this.$toast.error(this.$t("GERAL.MENSAGEM_CAMPO_VAZIO"), {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 1,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        alert = false;
      }

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    alterarTexto(items, key, index) {
      if (
        key == "cmc7" &&
        items[index]["cmc7"] &&
        items[index]["cmc7"]["cmc7"].length == 30
      ) {
        items[index]["numeroTitulo"]["numeroTitulo"] = items[index]["cmc7"][
          "cmc7"
        ].substring(11, 17);
      }
      this.habilitarSalvar(index, key);
    },
    abrirEditar(id, item, index) {
      if (!this.validarFormulario(this.selectedItemIndex)) return;

      if (
        this.selectedItemIndex >= 0 &&
        this.selectedItemIndex != this.items.length - 1
      ) {
        let oldIndex = this.selectedItemIndex;
        this.items[oldIndex].editavel = false;
        // console.log("this.selectedItemIndex-", this.selectedItemIndex);
        this.confirmEdit(this.items[oldIndex], oldIndex, true);
      }

      // console.log("index-", index);

      this.selectedItemIndex = index;
      this.clickEdit = true;
      this.$emit("abrirEditar", id);
    },
    duplicar(item) {
      let itemRet = { ...item };
      itemRet.existing = false;
      itemRet.editavel = true;
      itemRet.idItem = "";
      this.$emit("duplicar", itemRet);
    },
    excluir(id) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$emit("excluir", id);
        }
      });
    },
    habilitarSalvar(index, keyLabel) {
      if (!helpers.validarAcesso(this.permission).criar) return;

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        for (const key in this.dataValues[index]) {
          if(keyLabel==='cmc7' && key === 'cmc7') {
              if(this.dataValues[index][key][key].length < 30) {
                this.dataValues[index].habilitarSalvar = false;
                
                return;
              }
          }
          if (Object.hasOwnProperty.call(this.dataValues[index], key)) {
            if (
              typeof this.dataValues[index][key][key] == "string" &&
              this.dataValues[index][key][key].length > 0
            ) {
              this.dataValues[index][keyLabel].valido = true;

              this.dataValues[index].habilitarSalvar = true;
              // if(!this.clickEdit){
              //   this.dataValues[index][keyLabel].valido = true;
              // }else{
              //   this.clickEdit = false;
              // }

              return;
            } else {
              this.dataValues[index].habilitarSalvar = false;
            }
          }
        }
      }, 500);
    },
    salvarAoDarEnter(event, item, index) {
      this.clickEdit = true;
      if (event.which === 13) {
        this.confirmEdit(item, index);
      }
    },
    podeEditar() {
      // console.log("aaaaaaaaa");
    },
    async confirmEdit(item, index, noList) {
      this.clickEdit = true;
      if (this.validarFormulario(index) == false) return;

      if (!item.existing || item.existing == "") {
        this.$emit(
          "confirmEdit",
          this.dataValues,
          index,
          this.removerIndex,
          noList
        );
      } else {
        this.$emit("confirmUpdate", item, index, this.removerIndex, noList);
      }
    },
    removerIndex() {
      this.selectedItemIndex = undefined;
    },
    filterByValue(array, value) {
      return array.filter(
        (data) =>
          JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
    },
    selectItem(id, item) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].idItem == id) {
          item.active = !item.active;
          this.$set(this.items, i, item);
        }
      }
      this.$emit("selectItems", id);
    },
    //Classes e Styles
    classTabela(value, key) {
      return value[key] && value[key] != "" && value[key] != null
        ? "colls-template"
        : "center-template";
    },
    minimoTamanho(key) {
      let tamanho = 1;

      if (key == "email") {
        tamanho = 0;
      }

      if (key == "valorDesconto") {
        tamanho = 0;
      }

      if (key == "uf") {
        tamanho = 2;
      }

      if (key == "cmc7") {
        tamanho = 30;
      }

      return tamanho;
    },
    maximoTamanho(key) {
      let tamanho = 9999;

      if (key == "numeroTitulo") {
        tamanho = 10;
      }

      if (key == "uf") {
        tamanho = 2;
      }

      if (key == "cmc7") {
        tamanho = 30;
      }

      return tamanho;
    },
    desabilitarCampo(key) {
      return key == "numeroTitulo" && this.permission == "Cheques"
        ? true
        : false;
    },
    tamanhoCampo(key) {
      let tamanho;

      if (key === "email") {
        tamanho = "200px!important";
      }

      if (key === "uf") {
        tamanho = "70px";
      }

      return "min-width:" + tamanho;
    },
  },
  watch: {
    items() {
      this.todosAtivado = false;
      if (this.text) {
        return this.filterByValue(this.dataValues, this.text);
      } else {
        return this.dataValues;
      }
    },
  },
  computed: {
    items() {
      if (this.text) {
        return this.filterByValue(this.dataValues, this.text);
      } else {
        return this.dataValues;
      }
    },
    camposItems() {
      return this.camposData;
    },
    absoluteField() {
      return !this.moreFilters ? "absolute-field" : "";
    },
  },
};
</script>

<style>
.dropdown-acao {
  border: 1px solid;
  border-radius: 3px;
  margin: 3px;
  height: 35px;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  border-color: #6c757d;
}

.dropdown-acao:hover,
.dropdown-acao:focus {
  color: #6c757d;
  background-color: rgba(108, 117, 125, 0.3);
}

.icone-alerta {
  margin: auto;
  display: flex !important;
  color: #fab005;
}

.cpf-cnpj-sacado div {
  display: flex;
  align-items: center;
  min-width: 180px;
}

.div-item-tabela {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
