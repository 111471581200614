<template>
  <div>
    <div class="table-xml table-scroll">
      <Table
        :camposData="campos"
        :noPagination="true"
        :dataValues="data"
        permission="Cheques"
        @confirmEdit="
          (dataC, index, removerIndex) =>
            confirmEdit(dataC, index, undefined, removerIndex)
        "
        @duplicar="(item) => duplicar(item)"
        @confirmUpdate="
          (item, index, removerIndex) =>
            confirmUpdate(item, index, removerIndex)
        "
        @abrirEditar="(id) => abrirEditar(id)"
        @selectItems="
          (id) => {
            selecionarItems(id);
          }
        "
        @excluir="(id) => excluir(id)"
        @buscarPorCNPJ="
          (cnpj, campo, index) => buscarPorCNPJ(cnpj, campo, index)
        "
        :disableHeader="true"
        :optionsValue="optionsValue"
      />
    </div>
    <b-row class="operacoes-infos res-inputs">
      <acoes style="margin-top: 30px !important" :acao="actions" />
      <input-text
        :value="formatarValor(this.totalFace)"
        class="operacoes-text"
        :readonly="true"
        :label="$t('OPERACOES.totalFace')"
      />
      <!-- <input-text :value="formatarValor(this.totalLiquido)" class="operacoes-text" :readonly="true" :label="$t('OPERACOES.totalLiquido')" /> -->
      <div
        v-if="habilitarLimpar"
        v-on:click="limparCache"
        class="btn-operacoes normal"
      >
        {{ $t("OPERACOES.LIMPAR_OPERACOES") }}
      </div>
      <div
        v-if="habilitarOperacao"
        v-on:click="selecionarFavorecido()"
        class="btn-operacoes orange"
      >
        {{ $t("OPERACOES.VALIDAR_DADOS_BANCARIOS") }}
      </div>
    </b-row>

    <Favorecidos
      ref="favorecido"
      upload-arquivos
      @funcaoEnviar="
        (favorecido, arquivos, observacao) =>
          enviarOperacao(true, favorecido, arquivos, observacao)
      "
      title="Selecione o Favorecido da Operação"
    />
  </div>
</template>

<script>
import Table from "@/components/table/Table";
import InputText from "@/components/inputs/InputText";
import Acoes from "@/components/inputs/Acoes";
import helpers from "@/common/utils/helpers";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import Swal from "sweetalert2";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import ChequesService from "@/common/services/cheques/cheques.service";
import OperacoesService from "@/common/services/operacoes/operacoes.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { Cheques } from "@/common/models/Cheques";
import Util from "../utils/helper";
import Favorecidos from "./Favorecidos";

export default {
  name: "Cheques",
  components: {
    Table,
    InputText,
    Acoes,
    Favorecidos,
  },
  data() {
    return {
      habilitarLimpar: helpers.validarAcesso("Cheques").deletar,
      habilitarOperacao: helpers.validarAcesso("Operacao").criar,
      actions: [
        {
          titulo: this.$t("GERAL.EXCLUIR"),
          acao: this.acaoExcluir,
        },
      ],
      selectedItems: [],
      optionsValue: [
        { value: "CTe", text: "CTe" },
        { value: "NFe", text: "NFe" },
      ],
      desabilitarEnviarOperacao: false,
      campos: [
        { key: "item", label: "Item", thClass: "item-table" },
        { key: "numero", label: "N", thClass: "item-table" },
        {
          key: "acoes",
          label: "Ações",
          tdClass: "acoes acoes-index",
          thClass: "acao-table",
          stickyColumn: true,
        },
        {
          key: "cmc7",
          label: "CMC7*",
          editavel: true,
          editabelIndex: 1,
          thClass: "w-170",
        },
        {
          key: "numeroTitulo",
          label: "Numero do Titulo*",
          editavel: true,
          editabelIndex: 1,
        },
        {
          key: "cpfCnpjSacado",
          label: "CPF/CNPJ do Sacado*",
          editavel: true,
          editabelIndex: 2,
        },
        {
          key: "telefoneSacado",
          label: "Telefone do Sacado",
          editavel: true,
          editabelIndex: 1,
          thClass: "telefone-table",
        },
        {
          key: "nomeSacado",
          label: "Nome do Sacado*",
          editavel: true,
          editabelIndex: 2,
        },
        {
          key: "valorNominal",
          label: "Valor Nominal*",
          editavel: true,
          editabelIndex: 1,
        },
        {
          key: "dataEmissao",
          label: "Data da Emissão*",
          editavel: true,
          editabelIndex: 1,
        },
        {
          key: "dataVencimento",
          label: "Data de Vencimento*",
          editavel: true,
          editabelIndex: 1,
        },
        {
          key: "cidade",
          label: "Cidade",
          editavel: true,
          editabelIndex: 2,
          thClass: "w-170",
        },
        {
          key: "uf",
          label: "UF",
          editavel: true,
          editabelIndex: 2,
          thClass: "uf-table",
        },
        {
          key: "cep",
          label: "CEP",
          editavel: true,
          editabelIndex: 2,
          thClass: "cep-table",
        },
        {
          key: "endereco",
          label: "Endereço",
          editavel: true,
          editabelIndex: 2,
          thClass: "w-170",
        },
        {
          key: "bairro",
          label: "Bairro",
          editavel: true,
          editabelIndex: 2,
          thClass: "w-170",
        },
        {
          key: "numeroEndereco",
          label: "Numero do Endereço",
          editavel: true,
          editabelIndex: 2,
        },
        { key: "email", label: "Email", editavel: true, editabelIndex: 1 },
      ],
      data: new Cheques(),
      totalFace: 0,
      totalLiquido: 0,
    };
  },
  methods: {
    verificarSeSalvou() {
      let sendData = this.data;
      let notSave = false;

      for (let i = 0; i < sendData.length; i++) {
        for (const key in sendData[i]) {
          if (Object.hasOwnProperty.call(sendData[i], key)) {
            if (
              sendData[i]["editavel"] == true &&
              key != "new" &&
              key != "tipoTitulo" &&
              key != "erros" &&
              key != "tipo" &&
              key != "valido" &&
              key != "existing" &&
              key &&
              sendData[i][key][key]
            ) {
              notSave = true;
            }
          }
        }
      }

      return notSave;
    },
    selecionarFavorecido() {
      if (Util.verifyData(this.data, true) == false) {
        this.$toast.error(
          "Favor corrigir os erros apontados antes de selecionar favorecidos",
          {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        return;
      }
      if (this.verificarSeSalvou()) {
        this.$toast.error(
          "Por favor verifique se todas as informações foram salvas!",
          {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        return;
      }
      this.$refs.favorecido.inicializar();
      this.$bvModal.show("modal-favorecidos");
    },
    buscarPorCNPJ(cnpj, campo, index) {
      if (!cnpj || cnpj == null || cnpj == "") return;

      this.$store.dispatch(START_LOADING);
      OperacoesService.buscarDadosPorCNPJ(cnpj.replace(/[^\d]+/g, ""))
        .then((resultado) => {
          this.$set(this.data, index, { ...this.data[index], ...resultado[0] });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    acaoExcluir() {
      if (
        !this.selectedItems ||
        (this.selectedItems && this.selectedItems.length <= 0)
      ) {
        Swal.fire({
          title: this.$t("GERAL.ERRO"),
          text: this.$t("OPERACOES.ITEM_MSG"),
          icon: "error",
          confirmButtonText: this.$t("GERAL.OK"),
        }).then(() => {});
      } else {
        this.$store.dispatch(START_LOADING);

        let listIds = helpers.objectToQueryString(this.selectedItems);
        ChequesService.excluirVarios(listIds)
          .then(() => {
            this.listarTitulos();
          })
          .catch(() => {})
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
    selecionarItems(id) {
      if (this.selectedItems.includes(id)) {
        for (let i = 0; i < this.selectedItems.length; i++) {
          if (this.selectedItems[i] == id) {
            this.$delete(this.selectedItems, i);
          }
        }
      } else {
        this.selectedItems.push(id);
      }
    },
    async enviarOperacao(save, favorecido, arquivos, observacao) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          let operationSend = true;
          let index = this.data.length - 1;
          let notSave = false;
          if (save === true) {
            for (const key in this.data[index]) {
              if (Object.hasOwnProperty.call(this.data[index], key)) {
                if (
                  key != "new" &&
                  key != "editavel" &&
                  key != "existing" &&
                  key &&
                  !this.data[index][key][key]
                ) {
                  notSave = true;
                }
              }
            }

            if (!notSave) {
              operationSend = await this.confirmEdit(
                this.data,
                this.data.length - 1,
                true
              );
            }
          }

          if (operationSend) {
            Swal.fire({
              showConfirmButton: false,
              customClass: "sweet-enviando-operacao",
              title:
                "<div class='titulo-enviando-operacao'>Enviando Dados</div>",
              html:
                "<div class='desc-enviando-operacao'>Este passo pode demorar um pouco. Por favor, aguarde enquanto guardamos suas informações</div>" +
                "<div class='linePreloader'></div>",
            });

            let sendDate = [];

            for (let i = 0; i < this.data.length; i++) {
              if (
                this.data[i] &&
                this.data[i].idItem != "" &&
                this.data[i].idItem != null
              ) {
                sendDate.push(this.data[i].idItem);
              }
            }

            ChequesService.enviarOperacao({
              ids: sendDate,
              favorecido,
              arquivos,
              observacao,
            })
              .then((result) => {
                if (result.data.success) {
                  Swal.fire({
                    title: "Sucesso!",
                    text:
                      "A ação foi executada com sucesso!" +
                      " (Borderô: " +
                      result.data.data.bordero +
                      ")",
                    icon: "success",
                    confirmButtonText: this.$t("GERAL.OK"),
                  });

                  this.$bvModal.hide("modal-favorecidos");

                  this.totalFace = 0;
                  this.totalLiquido = 0;
                } else {
                  Swal.close();
                  this.$bvModal.hide("modal-favorecidos");
                }
              })
              .catch(() => {
                Swal.close();
                this.$bvModal.hide("modal-favorecidos");
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
                this.listarTitulos();
              });
          }
        }
      });
    },
    formatarValor(valor) {
      return helpers.formatarValor(valor);
    },
    loadFiles() {
      if (this.data && this.data.length > 0)
        this.$delete(this.data, this.data.length - 1);
    },

    confirmEdit(values, index, enviar, deletIndex) {
      values = [...values];

      this.$store.dispatch(START_LOADING);
      values[index].valorNominal.valorNominal = helpers.removerMascaraValor(
        values[index].valorNominal.valorNominal
      );
      ChequesService.salvar(values[index])
        .then((retorno) => {
          if (Util.verifyData(Util.formatData(retorno)) == false) {
            let retornoObj = Util.formatData(retorno)[0];
            retornoObj.editavel = true;
            retornoObj.idItem = retorno.data.data.titulo.id;
            this.$set(this.data, index, retornoObj);
            return false;
          }
          if (enviar) {
            this.enviarOperacao(false);
            return false;
          }

          deletIndex();
          this.data = [];
          this.listarTitulos();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    duplicar(item) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].editavel) {
          this.$delete(this.data, i);
        }
      }

      let retItem = JSON.parse(JSON.stringify(item));

      this.data.push(retItem);
    },

    itemSaveFormater() {},
    excluir(id) {
      this.$store.dispatch(START_LOADING);
      ChequesService.excluir(id)
        .then(() => {
          for (let i = 0; i < this.data.length; i++) {
            if (this.data[i].idItem == id) {
              this.$delete(this.data, i);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    confirmUpdate(item, index, deletIndex) {
      let itemSave = helpers.clonarObjeto(item);
      itemSave.editavel = false;

      this.totalFace = 0;
      this.totalLiquido = 0;

      this.$store.dispatch(START_LOADING);
      itemSave.valorNominal.valorNominal = helpers.removerMascaraValor(
        itemSave.valorNominal.valorNominal
      );
      ChequesService.salvar(itemSave)
        .then((result) => {
          if (Util.verifyData(Util.formatData(result)) == false) {
            let retornoObj = Util.formatData(result)[0];
            retornoObj.editavel = true;
            retornoObj.idItem = result.data.data.titulo.id;
            this.$set(this.data, index, retornoObj);
            // this.$set(this.data, index, Util.formatData(result)[0]);
            return;
          }
          deletIndex();
          this.listarTitulos();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirEditar(id) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].idItem == id) {
          this.$set(this.data[i], "editavel", true);
          this.$set(this.data[i], "existing", true);
        }
      }
    },
    limparCache() {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          ChequesService.limparCache({})
            .then((resutl) => {
              if (resutl.data.success) {
                console.log("new Cheques()", new Cheques());
                //this.data = new Cheques();
                this.listarTitulos();
                this.totalFace = 0;
                this.totalLiquido = 0;
              }
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    listarTitulos() {
      this.totalFace = 0;
      this.totalLiquido = 0;

      this.$store.dispatch(START_LOADING);
      ChequesService.listar_titulos({})
        .then((retornoService) => {
          let valorRetornado = retornoService.retornoFormatado;
          this.totalFace = retornoService.valorNominal;

          this.desabilitarEnviarOperacao = retornoService.titulosDuplicados;

          let itemEmBranco = new Cheques();
          this.data = [...valorRetornado, { ...itemEmBranco }];
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.OPERACOES") },
      { titulo: this.$t("OPERACOES.NOVA_OPERACAO") },
      { titulo: this.$t("OPERACOES.CHEQUES") },
    ]);
    this.listarTitulos();
  },
};
</script>
