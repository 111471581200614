<template>
  <div>
    <h6>Arquivo .REM e .TXT</h6>
    <hr class="mt-0" />
    <Upload
      :class="
        (data && data.length > 0) || !habilitarUpload
          ? 'disabled-upload'
          : undefined
      "
      v-if="data.length == 0"
      @onLoad="(infos) => loadFiles(infos)"
      type="cnab"
      tituloArquivo="Arraste seu arquivo"
      selecioneArquivo="Selecionar Arquivo..."
    />
    <b-table
      hover
      v-if="data.length > 0"
      small
      class="mt-4"
      head-variant="light"
      :fields="colunasTabela"
      :items="data"
    >
      <template #cell(nome)="data">
        <div class="d-flex align-items-center">
          <feather
            type="file-text"
            class="text-secondary mr-2"
            size="15px"
          ></feather>
          <div v-b-tooltip="data.item.nome" class="file-title">
            {{ data.item.nome }}
          </div>
        </div>
      </template>
      <template #cell(Acoes)="data">
        <b-button
          v-if="habilitarRemover"
          @click="excluir(data.item.id)"
          variant="danger"
          size="sm"
        >
          <div class="d-flex align-items-center">
            <feather type="trash-2" size="16px" class="mr-2"></feather>
            {{ $t("OPERACOES.REMOVER") }}
          </div>
        </b-button>
      </template>
    </b-table>

    <div v-if="data.length > 0">
      <h6>Arquivos .XMLs, .ZIP e .RAR</h6>
      <hr class="mt-0" />
      <Upload
        @onLoadXml="(infos) => loadFilesXml(infos)"
        type="cnab-xml"
        tituloArquivo="Arraste seu arquivo"
        selecioneArquivo="Selecionar Arquivo..."
        varios
      />

      <div>
        <b-button
          v-if="habilitarRemover"
          class="mt-4"
          variant="danger"
          size="sm"
          :disabled="xmlSelecionados.length == 0"
          @click="removerXmls"
        >
          <div class="d-flex align-items-center">
            <feather type="trash-2" size="16px" class="mr-2"></feather>
            {{ $t("OPERACOES.REMOVER_XMLS") }}
          </div>
        </b-button>
      </div>

      <b-table
        id="tabela-xml"
        :fields="colunasTabelaXml"
        :items="dataXml"
        class="mt-2"
        head-variant="light"
        hover
        small
        responsive
        :per-page="porPagina"
        :current-page="paginaAtual"
      >
        <template #cell(selected)="item">
          <input-checkbox v-model="item.item.selecionado" />
        </template>
        <template #head(selected)>
          <input-checkbox
            :value="todosSelecionado"
            @onChange="
              () => {
                selecionarTodos();
              }
            "
          />
        </template>

        <template #cell(nome)="data">
          <div class="d-flex align-items-center">
            <feather
              type="file-text"
              class="text-secondary mr-2"
              size="15px"
            ></feather>
            <div v-b-tooltip="data.item.nome" class="file-title">
              {{ data.item.nome }}
            </div>
          </div>
        </template>
        <template #cell(Acoes)="data">
          <b-button
            v-if="habilitarRemover"
            @click="excluirXml(data.item.id)"
            variant="danger"
            size="sm"
          >
            <div class="d-flex align-items-center">
              <feather type="trash-2" size="16px" class="mr-2"></feather>
              {{ $t("OPERACOES.REMOVER") }}
            </div>
          </b-button>
        </template>
      </b-table>

      <div class="d-flex justify-content-between align-items-center">
        <h6>{{ itemAtual }} a {{ itemFinal }} de {{ totalLinhasXml }} XMLs</h6>
        <b-pagination
          v-model="paginaAtual"
          :total-rows="totalLinhasXml"
          :per-page="porPagina"
          aria-controls="tabela-xml"
        ></b-pagination>
        <div class="d-flex align-items-center">
          <h6 class="mt-2 mr-2">Por pagina</h6>
          <input-select
            no-label
            :options="porPaginaOpcoes"
            v-model="porPagina"
          />
        </div>
      </div>
    </div>

    <b-row class="operacoes-infos res-inputs">
      <input-text
        :value="formatarValor(this.total)"
        class="operacoes-text"
        :readonly="true"
        :label="$t('OPERACOES.totalFace')"
      />
      <div
        v-if="habilitarLimpar"
        @click="limparOperacoes"
        class="btn-operacoes normal"
      >
        {{ $t("OPERACOES.LIMPAR_OPERACOES") }}
      </div>
      <div
        v-if="habilitarOperacao"
        @click="selecionarFavorecido()"
        :class="classSelecionarFavorecido"
      >
        {{ $t("OPERACOES.VALIDAR_DADOS_BANCARIOS") }}
      </div>
    </b-row>

    <Favorecidos
      @funcaoEnviar="
        (favorecido, arquivos, observacao) =>
          enviarOperacao(favorecido, observacao)
      "
      title="Selecione o Favorecido da Operação"
    />
  </div>
</template>

<script>
import Upload from "@/components/upload/Upload";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import Swal from "sweetalert2";
import CnabService from "@/common/services/cnab/cnab.service";
import Favorecidos from "./Favorecidos";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_STEP } from "@/store/operacoes.module";
import helpers from "@/common/utils/helpers";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import InputText from "@/components/inputs/InputText";
import InputCheckbox from "@/components/inputs/InputCheckboxModel.vue";
import InputSelect from "@/components/inputs/InputSelect.vue";
export default {
  name: "Cnab",
  components: {
    Upload,
    Favorecidos,
    InputText,
    InputCheckbox,
    InputSelect,
  },
  data() {
    return {
      habilitarLimpar: helpers.validarAcesso("Cnab").deletar,
      habilitarOperacao: helpers.validarAcesso("Operacao").criar,
      campos: [],
      data: [],
      dataXml: [],
      total: 0,
      total_face: 0,
      total_liquido: 0,
      habilitarRemover: helpers.validarAcesso("Cnab").deletar,
      colunasTabela: [
        { key: "nome", label: "Nome do arquivo", thClass: "w-75" },
        { key: "Acoes", label: "Ações" },
      ],
      colunasTabelaXml: [
        { key: "selected", label: "#" },
        { key: "nome", label: "Nome do arquivo", thClass: "w-75" },
        { key: "Acoes", label: "Ações" },
      ],
      paginaAtual: 1,
      porPagina: 10,
      todosSelecionado: false,
      porPaginaOpcoes: [10, 25, 50, 100],
    };
  },
  methods: {
    selecionarTodos() {
      this.todosSelecionado = !this.todosSelecionado;
      this.dataXml = this.dataXml.map((el) => {
        el.selecionado = this.todosSelecionado;
        return el;
      });
    },
    formatarValor(valor) {
      return helpers.formatarValor(valor);
    },
    selecionarFavorecido() {
      this.$bvModal.show("modal-favorecidos");
    },
    enviarOperacao(favorecidos, observacao) {
      Swal.fire({
        showConfirmButton: false,
        customClass: "sweet-enviando-operacao",
        title: "<div class='titulo-enviando-operacao'>Enviando Dados</div>",
        html:
          "<div class='desc-enviando-operacao'>Este passo pode demorar um pouco. Por favor, aguarde enquanto guardamos suas informações</div>" +
          "<div class='linePreloader'></div>",
      });

      // let Ids = [];

      // for (let i = 0; i < this.data.length; i++) {
      //   Ids.push(this.data[i].id);
      // }
      CnabService.enviarOperacao({
        Id: this.data[0].id,
        favorecido: favorecidos,
        arquivos: null,
        observacao,
      })
        .then((result) => {
          if (result.data.success) {
            Swal.fire({
              title: "Sucesso!",
              text:
                "A ação foi executada com sucesso!" +
                " (Borderô: " +
                result.data.data.bordero +
                ")",
              icon: "success",
              confirmButtonText: this.$t("GERAL.OK"),
            });
            this.data = [];
            this.dataXml = [];
            this.$bvModal.hide("modal-favorecidos");

            this.total = 0;
          } else {
            Swal.close();
            this.$bvModal.hide("modal-favorecidos");
          }
        })
        .catch(() => {
          Swal.close();
          this.$bvModal.hide("modal-favorecidos");
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limparOperacoes() {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          CnabService.limparCache({})
            .then(() => {
              this.listarCnab();
              this.listarXml();
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    removerXmls() {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          CnabService.excluirXmlVarios(this.xmlSelecionados)
            .then(() => {
              [...this.xmlSelecionados].forEach((element) => {
                this.dataXml.splice(
                  this.dataXml.findIndex((el) => el.id == element),
                  1
                );
              });
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    loadFiles(infos) {
      if (infos && infos.length == 0) return (this.data = []);
      // for (let i = 0; i < infos.length; i++) {
      //   this.data.push(infos[i]);
      // }

      this.listarCnab();
    },
    loadFilesXml(infos) {
      if (infos && infos.length == 0) return (this.data = []);
      // for (let i = 0; i < infos.length; i++) {
      //   this.data.push(infos[i]);
      // }

      this.listarXml();
    },
    listarCnab() {
      CnabService.listar_cnab({})
        .then((retorno) => {
          this.data = retorno;
          console.log("retorno", retorno);
          if (retorno.length > 0) {
            this.total = retorno[0].total;
            this.$store.dispatch(DEFINIR_STEP, 2);
          } else {
            this.total = 0;
            this.$store.dispatch(DEFINIR_STEP, 1);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    listarXml() {
      CnabService.listar_cnab_xml({})
        .then((retorno) => {
          this.dataXml = retorno.map((el) => {
            el.selecionado = false;
            return el;
          });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    excluir(id) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          CnabService.excluir(id)
            .then(() => {
              this.listarCnab();
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    excluirXml(id) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          this.$store.dispatch(START_LOADING);
          CnabService.excluirXml(id)
            .then(() => {
              this.dataXml.splice(
                this.dataXml.findIndex((el) => el.id == id),
                1
              );
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
  },
  computed: {
    itemAtual() {
      return this.totalLinhasXml > 0
        ? this.porPagina * (this.paginaAtual - 1) + 1
        : 0;
    },
    itemFinal() {
      let ultimoItem = this.porPagina * this.paginaAtual;
      return this.totalLinhasXml > 0
        ? this.totalLinhasXml < ultimoItem
          ? this.totalLinhasXml
          : ultimoItem
        : 0;
    },
    totalLinhasXml() {
      return this.dataXml.length;
    },
    classSelecionarFavorecido() {
      return this.data.length <= 0
        ? "btn-operacoes orange btn-desativado"
        : "btn-operacoes orange";
    },
    habilitarUpload() {
      return helpers.validarAcesso("Cnab").criar;
    },
    xmlSelecionados() {
      return this.dataXml.filter((el) => el.selecionado).map((el) => el.id);
    },
  },
  watch: {
    dataXml: {
      handler() {
        let todos = this.dataXml.every((el) => {
          return el.selecionado;
        });
        if (!todos) {
          this.todosSelecionado = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.listarCnab();
    this.listarXml();
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.OPERACOES") },
      { titulo: this.$t("OPERACOES.NOVA_OPERACAO") },
      { titulo: this.$t("OPERACOES.CNAB") },
    ]);
  },
};
</script>
