<template>
  <div>
    <modal TituloConfirmar="Enviar Operação" :habilitarConfirmar="infoFavorecido ? true : false" @funcaoConfirmar="enviarOperacao" :id="id" :title="title">
      <div class="inputs-facorecido">
        <b-form-input v-model="text" placeholder="Busque por Favorecido" class="pesquisa"></b-form-input>
        <input-select
          :options="tipoEmpresa"
          v-model="tipoEmpresaSelecionado"
          @input="
            (valor) => {
              selecionarEmpresa(valor);
            }
          "
          placeholder="Tipo"
          class="pesquisa"
        ></input-select>
        <b-button variant="primary">Filtrar</b-button>
      </div>

      <b-table class="text-xsmall" :fields="camposItems" striped hover :items="items">
        <template style="width: 50px" v-slot:cell(#)="item">
          <b-form-radio v-model="selecionado" @change="selecionarFavorecido(item.item)" name="some-radios"></b-form-radio>
        </template>

        <template v-slot:cell(nome)="item">
          <div class="td-size" v-b-tooltip="item.item.nome">
            {{ item.item.nome }}
          </div>
        </template>
      </b-table>

      <input-text-area label="Observações" v-model="observacao"/>

      <hr v-if="uploadArquivos">
      <div v-if="uploadArquivos">
        <h6>Importação de arquivos</h6>
        <upload varios type="vazio" types=".jpeg, .jpg, .png, .bmp, .pdf" @upload="(arquivo) => {
            this.arquivos.push(arquivo);
          }" />
        <b-list-group class="mt-2">
        <div style="max-height:100px; overflow:auto;">
          <b-list-group-item v-for="arquivo,i in arquivos" :key="`arquivo_${i}`" class="d-flex align-items-center justify-content-between p-1">
            <span>{{arquivo.nome}}</span>
            <b-button @click="removerArquivo(i)" variant="ligth" class="d-flex align-items-center">
              <feather
                type="trash"
                class="mr-2 feather-sm"
              ></feather>
              Remover
            </b-button>
          </b-list-group-item>
        </div>
        </b-list-group> 
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/modal/Modal";
import InputSelect from "@/components/inputs/InputSelect";
import {InputTextArea} from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import FavorecidosService from "@/common/services/favorecidos/favorecidos.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import Upload from "../../../components/upload/Upload.vue";
export default {
  name: "Favorecidos",
  props: {
    title: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "modal-favorecidos",
    },
    uploadArquivos:{
      type : Boolean,
      default : false
    }
  },
  components: {
    Modal,
    InputSelect,
    InputTextArea,
    Upload,
  },
  data() {
    return {
      selecionado: "",
      text: undefined,
      tipoEmpresaSelecionado: null,
      tipoEmpresa: [
        {
          value: null,
          text: "Selecione o Tipo",
        },
        {
          value: "P",
          text: "Empresa",
        },
        {
          value: "T",
          text: "Terceiro",
        },
      ],
      camposItems: [
        { key: "#", label: "#" },
        { key: "codigo", label: "Cód." },
        { key: "nome", label: "Nome", tdClass: "td-size" },
        { key: "banco", label: "Banco" },
        { key: "ag_conta", label: "Ag/Conta", thClass: "th-size" },
        { key: "pix", label: "PIX", thClass: "th-size", tdClass: "td-size" },
        { key: "indicador", label: "Tit." },
      ],
      dataValues: [],
      infoFavorecido: undefined,
      arquivos : [],
      observacao : "",
    };
  },
  methods: {
    inicializar(){
      this.arquivos = [];
      this.observacao = "";
    },
    removerArquivo(i){
      this.arquivos.splice(i,1);
    },
    selecionarFavorecido(item) {
      this.infoFavorecido = {
        codigo: item.codigo,
        indicador: item.indicador,
      };
    },

    filtrarIndicador(array, valor) {
      let arr = array.filter((data) => {
        return data.indicador === valor;
      });

      return arr;
    },

    selecionarEmpresa(valor) {
      this.items = this.filtrarIndicador(this.dataValues, valor);
    },
    enviarOperacao() {
      this.$emit("funcaoEnviar", this.infoFavorecido, this.arquivos, this.observacao);
    },
    listarFavorecidos() {
      this.$store.dispatch(START_LOADING);
      FavorecidosService.listar_favorecidos({})
        .then((retornoService) => {
          this.dataValues = retornoService;
        })
        .catch(() => {
         
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  computed: {
    items() {
      if (this.tipoEmpresaSelecionado) {
        return this.filtrarIndicador(this.dataValues, this.tipoEmpresaSelecionado);
      }

      if (this.text) {
        return helpers.filterByValue(this.dataValues, this.text || this.tipoEmpresaSelecionado);
      } else {
        return this.dataValues;
      }
    },
  },
  mounted() {
    this.listarFavorecidos();
    this.inicializar();
  },
};
</script>
