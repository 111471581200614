import ApiService from "@/common/api/api.service";

const CnabService = {
  importarCnab(form, file) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `cnab/importar`,
        { ...form },
        { noToast: true },
        file[0],
        true
      )
        .then((res) => {
          let valorRetornado = [];

          let arquivos = res.data.data;

          valorRetornado.push({
            id: arquivos.id,
            nome: arquivos.nomeArquivo,
          });
          resolve(valorRetornado);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  validarCnab(file) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `cnab/validar`,
        {},
        { noToast: true, noError : true },
        file[0],
        true
      )
        .then((res) => {
            resolve(res.data.data);
        })
        .catch((err) => {
          reject(err)          
        })
    });
  },
  async importarXmlCnab(form, file) {
    let retorno = await ApiService.post(
      `cnab/importar-xml`,
      { ...form },
      { noToast: true },
      file
    );

    let valorRetornado = [];

    let arquivos = retorno.data.data.arquivos;

    for (let i = 0; i < arquivos.length; i++) {
      valorRetornado.push({
        id: arquivos[i].id,
        nome: arquivos[i].nomeArquivo,
      });
    }

    return valorRetornado;
  },
  async listar_cnab() {
    let retorno = await ApiService.get(`cnab`);
    let valorRetornado = [];
    let arquivos = retorno.data.data.cnabs;
    for (let i = 0; i < arquivos.length; i++) {
      valorRetornado.push({
        id: arquivos[i].id,
        nome: arquivos[i].nome,
        total: arquivos[i].total,
      });
    }

    return valorRetornado;
  },

  async listar_cnab_xml() {
    let retorno = await ApiService.get(`cnab/xml-cnab`);
    let valorRetornado = [];
    let arquivos = retorno.data.data.cnabs;
    for (let i = 0; i < arquivos.length; i++) {
      valorRetornado.push({
        id: arquivos[i].id,
        nome: arquivos[i].nome,
      });
    }

    return valorRetornado;
  },
  enviarOperacao(form, file) {
    return ApiService.post(`cnab/enviar`, { ...form }, {}, file);
  },
  limparCache(form, file) {
    return ApiService.post(`cnab/limpar-cache`, { ...form }, {}, file);
  },
  excluir(id) {
    return ApiService.delete(`cnab/?id=${id}`);
  },
  excluirXml(id) {
    return ApiService.delete(`cnab/xml-cnab/?id=${id}`);
  },
  excluirXmlVarios(form) {
    return ApiService.delete(`cnab/xml-cnab-varios`, form);
  },
};

export default CnabService;
