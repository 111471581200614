//import helpers from "../utils/helpers";
export class NotaPromissoria {
  constructor() {
    this.id = "";
    this.titulos = "";
    this.idItem = "";
    this.existing = "";
    this.new = true;
    this.editavel = true;
    this.habilitarSalvar = false;
    
    this.cpfCnpjSacado = {
      cpfCnpjSacado: "",
      tipo: "CNPJ",
      editavel: true,
      obrigatorio: true,
    };
    this.numeroTitulo = {
      numeroTitulo: "",
      tipo: "TEXT",
      editavel: true,
      obrigatorio: true,
    };
    this.nomeSacado = {
      nomeSacado: "",
      tipo: "TEXT",
      editavel: true,
      obrigatorio: true,
    };
    this.telefoneSacado = {
      tipo: "PHONE",
      editavel: true,
      telefoneSacado: "",
      obrigatorio: false,
    };
    this.dataEmissao = {
      dataEmissao: "",
      tipo: "DATE",
      editavel: true,
      obrigatorio: true,
    };
    this.dataVencimento = {
      dataVencimento: "",
      tipo: "DATE",
      editavel: true,
      obrigatorio: true,
    };
    this.valorNominal = {
      valorNominal: null,
      tipo: "VALOR",
      editavel: true,
      obrigatorio: true,
    };
    this.valorDesconto = {
      valorDesconto: null,
      tipo: "VALOR",
      editavel: true,
      obrigatorio: false,
    };
    this.endereco = {
      endereco: "",
      tipo: "TEXT",
      editavel: true,
      obrigatorio: true,
    };
    this.bairro = {
      bairro: "",
      tipo: "TEXT",
      editavel: true,
      obrigatorio: true,
    };
    this.cidade = {
      cidade: "",
      tipo: "TEXT",
      editavel: true,
      obrigatorio: true,
    };
    this.uf = { uf: "", tipo: "TEXT", editavel: true, obrigatorio: true };
    this.cep = { cep: "", tipo: "CEP", editavel: true, obrigatorio: true };
    this.email = {
      email: "",
      tipo: "TEXT",
      editavel: true,
      obrigatorio: false,
    };
    this.numeroEndereco = {
      numero: "0",
      tipo: "TEXT",
      editavel: true,
      numeroEndereco: "",
      obrigatorio: true,
    };
  }
}
