<template>
  <div>
    <Upload @onLoad="(infos) => listarTitulos()" />
    <div class="botoes-acoes">
      <b-button
        :disabled="itemsSelecionados.length < 2"
        @click="editarTodos"
        variant="primary"
        :class="classBotaoEditar"
      >
        <feather type="edit-2" class="icones-btn"></feather>
        {{ $t("GERAL.EDITAR_TODOS") }}
      </b-button>
      <b-button
        :disabled="itemsSelecionados.length == 0"
        @click="acaoExcluir"
        variant="danger"
        :class="classBotaoExcluir"
      >
        <feather type="trash" class="icones-btn"></feather>
        {{ $t("GERAL.EXCLUIR") }}</b-button
      >
    </div>
    <div class="table-xml table-scroll">
      <Table
        ref="table-xml"
        :camposData="campos"
        :noPagination="true"
        permission="Titulos"
        :dataValues="data"
        @confirmEdit="
          (dataC, index, removerIndex, noList) =>
            confirmEdit(dataC, index, undefined, removerIndex, noList)
        "
        @duplicar="(item) => duplicar(item)"
        @confirmUpdate="
          (item, index, removerIndex, noList) =>
            confirmUpdate(item, index, removerIndex, noList)
        "
        @abrirEditar="(id) => abrirEditar(id)"
        @selectItems="
          (id) => {
            selecionarItems(id);
          }
        "
        @buscarPorCNPJ="
          (cnpj, campo, index) => buscarPorCNPJ(cnpj, campo, index)
        "
        @excluir="(id) => excluir(id)"
        paginado
        @paginar="paginar"
        :total-linhas="paginacao.quantidadeLinhas"
        :disableHeader="true"
        :optionsValue="optionsValue"
      />
    </div>
    <b-row class="operacoes-infos res-inputs">
      <input-text
        :value="formatarValor(this.totalFace)"
        class="operacoes-text"
        :readonly="true"
        :label="$t('OPERACOES.totalFace')"
      />
      <input-text
        :value="formatarValor(this.totalLiquido)"
        class="operacoes-text"
        :readonly="true"
        :label="$t('OPERACOES.totalLiquido')"
      />
      <div
        v-if="habilitarLimpar"
        v-on:click="limparCache"
        class="btn-operacoes normal"
      >
        {{ $t("OPERACOES.LIMPAR_OPERACOES") }}
      </div>
      <div
        v-if="habilitarOperacao"
        @click="selecionarFavorecido()"
        :class="classSelecionarFavorecido"
      >
        {{ $t("OPERACOES.VALIDAR_DADOS_BANCARIOS") }}
      </div>
    </b-row>

    <Favorecidos
      ref="favorecido"
      :upload-arquivos="houveInclusaoManual"
      @funcaoEnviar="
        (favorecido, arquivos, observacao) =>
          enviarOperacao(true, favorecido, arquivos, observacao)
      "
      title="Selecione o Favorecido da Operação"
    />

    <modal
      :TituloConfirmar="$t('OPERACOES.ALTERAR_CAMPO')"
      :sairDoModal="campoSelecionado ? false : true"
      @funcaoVoltar="limparCampoSelecionado"
      @funcaoConfirmar="enviarEdicaoEmMassa"
      :habilitarConfirmar="campoSelecionado ? true : false"
      id="editar-todos-modal"
      :title="$t('OPERACOES.CAMPOS_EDITAR')"
      size="md"
      variant="primary"
    >
      <div v-if="!campoSelecionado" class="res-inputs inputs-editar">
        <b-button
          @click="campoSelecionado = 'datavencimento'"
          size="lg"
          variant="outline-primary"
          class="btn-editar"
        >
          {{ $t("CAMPOS_TABELA_TITULOS.VENCIMENTO") }}
        </b-button>
        <b-button
          @click="campoSelecionado = 'telefone'"
          size="lg"
          variant="outline-primary"
          class="btn-editar"
        >
          {{ $t("CAMPOS_TABELA_TITULOS.TELEFONE") }}
        </b-button>
        <!-- <b-button
          @click="campoSelecionado = 'documento'"
          size="lg"
          variant="outline-primary"
          class="btn-editar"
        >
          {{ $t("CAMPOS_TABELA_TITULOS.CPF_CNPJ") }}
        </b-button> -->
        <b-button
          @click="campoSelecionado = 'email'"
          size="lg"
          variant="outline-primary"
          class="btn-editar"
        >
          {{ $t("CAMPOS_TABELA_TITULOS.EMAIL") }}
        </b-button>
      </div>

      <div class="campos">
        <input-date
          v-if="campoSelecionado == 'datavencimento'"
          ref="valorCampoSelecionado"
          class="form-editavel"
          :label="$t('CAMPOS_TABELA_TITULOS.VENCIMENTO')"
          v-model="valorCampoSelecionado"
          required
        />
        <input-text
          :label="$t('CAMPOS_TABELA_TITULOS.TELEFONE')"
          ref="valorCampoSelecionado"
          :minLength="13"
          v-if="campoSelecionado == 'telefone'"
          v-mask="['(##)####-####', '#####-####', '(##)#####-####']"
          v-model="valorCampoSelecionado"
          required
        />
        <input-text
          :label="$t('CAMPOS_TABELA_TITULOS.CPF_CNPJ')"
          ref="valorCampoSelecionado"
          v-if="campoSelecionado == 'documento'"
          v-mask="['###.###.###-##', '##.###.###/####-##']"
          v-model="valorCampoSelecionado"
          required
        />
        <input-text
          :label="$t('CAMPOS_TABELA_TITULOS.EMAIL')"
          ref="valorCampoSelecionado"
          v-if="campoSelecionado == 'email'"
          type="email"
          v-model="valorCampoSelecionado"
          required
        />
      </div>
    </modal>
  </div>
</template>

<script>
import Upload from "@/components/upload/Upload";
import Table from "@/components/table/Table";
import { DEFINIR_STEP } from "@/store/operacoes.module";
import InputText from "@/components/inputs/InputText";
import helpers from "@/common/utils/helpers";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { TitulosENotas } from "@/common/models/TitulosENotas";
import Confirmacao from "@/components/confirmacao/Confirmacao";
import Modal from "@/components/modal/Modal";
import Swal from "sweetalert2";
import TitulosNotasService from "@/common/services/titulos-notas/titulos-notas.service";
import OperacoesService from "@/common/services/operacoes/operacoes.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import Favorecidos from "./Favorecidos";
import InputDate from "@/components/inputs/InputDate";

import Util from "../utils/helper";

export default {
  name: "TitulosNotas",
  components: {
    Upload,
    Table,
    InputText,
    Favorecidos,
    InputDate,
    Modal,
  },
  data() {
    return {
      houveInclusaoManual: false,
      favorecido: undefined,
      habilitarLimpar: helpers.validarAcesso("Titulos").deletar,
      habilitarOperacao: helpers.validarAcesso("Operacao").criar,
      campoSelecionado: undefined,
      valorCampoSelecionado: undefined,
      actions: [
        {
          titulo: this.$t("GERAL.EXCLUIR"),
          acao: this.acaoExcluir,
          habilitar: helpers.validarAcesso("Titulos").deletar,
        },
      ],
      itemsSelecionados: [],
      optionsValue: [
        { value: "CTe", text: "CTe" },
        { value: "NFe", text: "NFe" },
      ],
      campos: [
        {
          key: "item",
          label: "",
          thClass: "item-table",
        },
        {
          key: "numero",
          label: "",
          thClass: "item-table",
        },
        {
          key: "acoes",
          label: "",
          tdClass: "acoes acoes-index",
          thClass: "acao-table",
          stickyColumn: true,
        },
        {
          key: "numeroTitulo",
          label: "Numero do Titulo *",
          editavel: true,
          editabelIndex: 1,
          required: true,
        },
        {
          key: "valorNominal",
          label: "Valor Face *",
          editavel: true,
          editabelIndex: 1,
          thClass: "valor-table",
          required: true,
        },
        {
          key: "valorDesconto",
          label: "Valor Desconto",
          editavel: true,
          thClass: "valor-table",
          editabelIndex: 1,
          required: false,
        },
        {
          key: "dataEmissao",
          label: "Data da Emissão *",
          editavel: true,
          editabelIndex: 1,
          required: true,
        },
        {
          key: "dataVencimento",
          label: "Data de Vencimento *",
          editavel: true,
          editabelIndex: 1,
          required: true,
        },
        {
          key: "cpfCnpjSacado",
          label: "CPF/CNPJ do Sacado *",
          editavel: true,
          editabelIndex: 2,
          required: true,
          tdClass: "cpf-cnpj-sacado",
        },
        {
          key: "nomeSacado",
          label: "Nome do Sacado *",
          editavel: true,
          editabelIndex: 2,
          required: true,
        },
        {
          key: "email",
          label: "Email",
          editavel: true,
          editabelIndex: 1,
        },
        {
          key: "telefoneSacado",
          label: "Telefone do Sacado",
          thClass: "telefone-sacado",
          editavel: true,
          editabelIndex: 1,
        },
        {
          key: "endereco",
          label: "Endereço *",
          editavel: true,
          editabelIndex: 2,
          required: true,
          thClass: "w-170",
        },
        {
          key: "cep",
          label: "CEP *",
          editavel: true,
          editabelIndex: 2,
          required: true,
          thClass: "cep-table",
        },
        {
          key: "numeroEndereco",
          label: "Numero do Endereço *",
          editavel: true,
          editabelIndex: 2,
          required: true,
        },
        {
          key: "bairro",
          label: "Bairro *",
          editavel: true,
          editabelIndex: 2,
          required: true,
          thClass: "w-170",
        },
        {
          key: "cidade",
          label: "Cidade *",
          editavel: true,
          editabelIndex: 2,
          required: true,
          thClass: "w-170",
        },
        {
          key: "uf",
          label: "UF *",
          editavel: true,
          editabelIndex: 2,
          thClass: "uf-table",
          required: true,
        },
        {
          key: "chaveNfe",
          label: "Chave NFe",
          editavel: true,
          editabelIndex: 2,
        },
      ],
      data: [],
      totalFace: 0,
      totalLiquido: 0,
      paginacao: {
        quantidadeLinhas: 0,
        paginaAtual: 0,
        porPagina: 100,
      },
    };
  },
  methods: {
    limparCampoSelecionado() {
      this.campoSelecionado = undefined;
      this.valorCampoSelecionado = undefined;
    },
    validarEdicaoEmMassa() {
      let arValidation = [];
      arValidation.push(this.$refs.valorCampoSelecionado.valid());

      // console.log("arValidation", arValidation);

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    formatarTelefoneEdicao(telefone) {
      let telefoneNumeros = telefone.replace(/[^0-9]/g, "");
      let ddd = telefoneNumeros.toString().substring(0, 2);
      let celular = telefoneNumeros.toString().substring(2);

      return ddd + "-" + celular;
    },
    enviarEdicaoEmMassa() {
      if (!this.validarEdicaoEmMassa()) return;

      let form = {
        ValorString: this.valorCampoSelecionado,
        NomeCampo: this.campoSelecionado,
        ids: this.itemsSelecionados,
      };

      if (this.campoSelecionado == "telefone") {
        form.ValorString = this.formatarTelefoneEdicao(
          this.valorCampoSelecionado
        );
      }

      if (this.campoSelecionado == "datavencimento") {
        form.ValorDateTime = this.valorCampoSelecionado;
      }

      TitulosNotasService.EnviarEdicaoEmMassa(form)
        .then(() => {
          this.$bvModal.hide("editar-todos-modal");
          this.limparCampoSelecionado();
          this.itemsSelecionados = [];
          this.listarTitulos(
            null,
            this.paginacao.paginaAtual,
            this.paginacao.porPagina
          );
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    selecionarFavorecido() {
      if (Util.verifyData(this.data, true) == false) {
        this.$toast.error(
          "Favor corrigir os erros apontados antes de selecionar favorecidos",
          {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );

        return;
      }

      if (this.verificarSeSalvou()) {
        this.$toast.error(
          "Por favor verifique se todas as informações foram salvas!",
          {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
        return;
      }
      this.$refs.favorecido.inicializar();
      this.$bvModal.show("modal-favorecidos");
    },
    buscarPorCNPJ(cnpj, campo, index) {
      if (!cnpj || cnpj == null || cnpj == "") return;
      this.$store.dispatch(START_LOADING);
      OperacoesService.buscarDadosPorCNPJ(cnpj.replace(/[^\d]+/g, ""))
        .then((resultado) => {
          this.$set(this.data, index, { ...this.data[index], ...resultado[0] });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editarTodos() {
      if (
        !this.itemsSelecionados ||
        (this.itemsSelecionados && this.itemsSelecionados.length <= 0)
      ) {
        Swal.fire({
          title: this.$t("GERAL.ERRO"),
          text: this.$t("OPERACOES.ITEM_MSG"),
          icon: "error",
          confirmButtonText: this.$t("GERAL.OK"),
        }).then(() => {});
      } else {
        this.$bvModal.show("editar-todos-modal");
      }
    },

    acaoExcluir() {
      if (
        !this.itemsSelecionados ||
        (this.itemsSelecionados && this.itemsSelecionados.length <= 0)
      ) {
        Swal.fire({
          title: this.$t("GERAL.ERRO"),
          text: this.$t("OPERACOES.ITEM_MSG"),
          icon: "error",
          confirmButtonText: this.$t("GERAL.OK"),
        }).then(() => {});
      } else {
        Confirmacao.SimNao(
          this,
          null,
          this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
        ).then(async (result) => {
          if (!result.value) return;

          this.$store.dispatch(START_LOADING);
          TitulosNotasService.excluirVarios(this.itemsSelecionados)
            .then(() => {
              this.itemsSelecionados = [];
              this.listarTitulos(
                null,
                this.paginacao.paginaAtual,
                this.paginacao.porPagina
              );
            })
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        });
      }
    },
    verificarSeSalvou() {
      let sendData = this.data;
      let notSave = false;

      for (let i = 0; i < sendData.length; i++) {
        for (const key in sendData[i]) {
          if (Object.hasOwnProperty.call(sendData[i], key)) {
            if (
              sendData[i]["editavel"] == true &&
              key != "new" &&
              key != "tipo" &&
              key != "erros" &&
              key != "valido" &&
              key != "existing" &&
              key &&
              sendData[i][key][key]
            ) {
              notSave = true;
            }
          }
        }
      }

      return notSave;
    },
    selecionarItems(id) {
      if (this.itemsSelecionados.includes(id)) {
        for (let i = 0; i < this.itemsSelecionados.length; i++) {
          if (this.itemsSelecionados[i] == id) {
            this.$delete(this.itemsSelecionados, i);
          }
        }
      } else {
        this.itemsSelecionados.push(id);
      }
    },
    async enviarOperacao(save, favorecido, arquivos, observacao) {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          let operationSend = true;

          if (operationSend) {
            Swal.fire({
              showConfirmButton: false,
              customClass: "sweet-enviando-operacao",
              title:
                "<div class='titulo-enviando-operacao'>Enviando Dados</div>",
              html:
                "<div class='desc-enviando-operacao'>Este passo pode demorar um pouco. Por favor, aguarde enquanto guardamos suas informações</div>" +
                "<div class='linePreloader'></div>",
            });

            // let sendDate = [];
            // for (let i = 0; i < this.data.length; i++) {
            //   if (
            //     this.data[i] &&
            //     this.data[i].idItem != "" &&
            //     this.data[i].idItem != null
            //   ) {
            //     sendDate.push(this.data[i].idItem);
            //   }
            // }

            TitulosNotasService.enviarOperacao({
              // Ids: sendDate,
              favorecido: favorecido || this.favorecido,
              arquivos,
              observacao,
            })
              .then((result) => {
                if (result.data.success) {
                  Swal.fire({
                    title: "Sucesso!",
                    text:
                      "A ação foi executada com sucesso!" +
                      " (Borderô: " +
                      result.data.data.bordero +
                      ")",
                    icon: "success",
                    confirmButtonText: this.$t("GERAL.OK"),
                  });
                  let itemEmBranco = new TitulosENotas();
                  this.data = [{ ...itemEmBranco }];
                  this.$bvModal.hide("modal-favorecidos");

                  this.totalFace = 0;
                  this.totalLiquido = 0;
                } else {
                  Swal.close();
                  this.$bvModal.hide("modal-favorecidos");
                }
              })
              .catch(() => {
                Swal.close();
                this.$bvModal.hide("modal-favorecidos");
              })
              .finally(() => {
                this.$store.dispatch(STOP_LOADING);
              });
          }
        }
      });
    },
    formatarValor(valor) {
      return helpers.formatarValor(valor);
    },
    loadFiles() {
      if (this.data && this.data.length > 0)
        this.$delete(this.data, this.data.length - 1);
    },

    confirmEdit(values, index, enviar, deletIndex, noList) {
      values = [...values];

      this.$store.dispatch(START_LOADING);
      values[index].valorNominal.valorNominal = helpers.removerMascaraValor(
        values[index].valorNominal.valorNominal
      );
      values[index].valorDesconto.valorDesconto = helpers.removerMascaraValor(
        values[index].valorDesconto.valorDesconto
      );
      TitulosNotasService.salvar(values[index])
        .then(async (retorno) => {
          if (Util.verifyData(Util.formatData(retorno)) == false) {
            let item = Util.formatData(retorno)[0];

            item.editavel = true;
            this.$set(this.data, index, item);

            return false;
          }

          if (enviar) {
            this.enviarOperacao(false);
            return false;
          }

          this.listarTitulos(
            noList,
            this.paginacao.paginaAtual,
            this.paginacao.porPagina
          );

          if (!noList) {
            deletIndex();
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    formatarCep(campo) {
      var v = campo.replace(/D/g, "");
      v = v.replace(/^(d{5})(d)/, "$1-$2");
      campo = v;

      return campo;
    },
    duplicar(item) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].editavel) {
          this.$delete(this.data, i);
        }
      }
      for (const key in item) {
        if (Object.hasOwnProperty.call(item, key)) {
          if (
            key != "idItem" &&
            key != "editavel" &&
            key != "existing" &&
            key != "id" &&
            key != "new" &&
            key != "erros" &&
            key != "tipoTitulo" &&
            key != "titulos" &&
            key != "obrigatorio" &&
            key != "tipo" &&
            key != "habilitarSalvar" &&
            key != "valido" &&
            key != "flagEditado"
          ) {
            item[key]["editavel"] = true;
            item[key]["tipoTitulo"] = undefined;
          }
        }
      }

      let retItem = JSON.parse(JSON.stringify(item));

      this.data.push(retItem);
    },

    excluir(id) {
      this.$store.dispatch(START_LOADING);
      TitulosNotasService.excluir(id)
        .then(() => {
          this.listarTitulos(
            null,
            this.paginacao.paginaAtual,
            this.paginacao.porPagina
          );
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    confirmUpdate(item, index, deletIndex, noList) {
      let itemSave = helpers.clonarObjeto(item);
      itemSave.editavel = false;

      this.totalFace = 0;
      this.totalLiquido = 0;

      itemSave.valorNominal.valorNominal = helpers.removerMascaraValor(
        itemSave.valorNominal.valorNominal
      );
      itemSave.valorDesconto.valorDesconto = helpers.removerMascaraValor(
        itemSave.valorDesconto.valorDesconto
      );

      this.$store.dispatch(START_LOADING);
      TitulosNotasService.salvar(itemSave)
        .then((result) => {
          if (Util.verifyData(Util.formatData(result)) == false) {
            let titulo = Util.formatData(result)[0];
            titulo.editavel = true;
            titulo.habilitarSalvar = true;
            this.$set(this.data, index, titulo);
            return;
          }

          let titulo = Util.formatData(result)[0];
          titulo.editavel = false;
          this.$set(this.data, index, titulo);

          this.listarTitulos(
            noList,
            this.paginacao.paginaAtual,
            this.paginacao.porPagina
          );
          if (!noList) {
            deletIndex();
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirEditar(id) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].idItem == id) {
          // console.log("this.data[i]", this.data[i]);
          this.$set(this.data[i], "editavel", true);
          this.$set(this.data[i], "existing", true);
        }
      }
    },
    limparCache() {
      Confirmacao.SimNao(
        this,
        null,
        this.$t("GERAL.MENSAGEM_CONFIRMAR_ACAO")
      ).then(async (result) => {
        if (result.value) {
          TitulosNotasService.limparCache({})
            .then((resutl) => {
              if (resutl.data.success) {
                this.data = new TitulosENotas();
                this.listarTitulos();

                this.totalFace = 0;
                this.totalLiquido = 0;
              }
            })
            .catch(() => {})
            .finally(() => {
              this.$store.dispatch(STOP_LOADING);
            });
        }
      });
    },
    paginar(pagina, porPagina) {
      this.paginacao.paginaAtual = pagina - 1;
      this.paginacao.porPagina = porPagina;

      this.listarTitulos(
        null,
        this.paginacao.paginaAtual,
        this.paginacao.porPagina
      );
    },
    listarTitulos(noList, pagina = 0, porPagina = 100) {
      this.totalFace = 0;
      this.totalLiquido = 0;
      this.itemsSelecionados = [];

      this.$store.dispatch(START_LOADING);
      TitulosNotasService.listar_titulos(pagina, porPagina)
        .then((retornoService) => {
          this.houveInclusaoManual = retornoService.manual;
          this.paginacao.quantidadeLinhas =
            retornoService.paginacao.totalDeElementos;

          let valorRetornado = retornoService.retornoFormatado;

          this.totalFace = retornoService.valorTotalFace;
          this.totalLiquido = retornoService.valorTotalLiquido;

          let itemEmBranco = new TitulosENotas();

          if (valorRetornado.length > 0) {
            this.$store.dispatch(DEFINIR_STEP, 2);
          } else {
            this.$store.dispatch(DEFINIR_STEP, 1);
          }

          if (!noList) {
            this.data = [...valorRetornado, { ...itemEmBranco }];
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.OPERACOES") },
      { titulo: this.$t("OPERACOES.NOVA_OPERACAO") },
      { titulo: this.$t("OPERACOES.TITULOS_NOTAS") },
    ]);
    this.listarTitulos();
  },
  computed: {
    classSelecionarFavorecido() {
      return this.data.length <= 0
        ? "btn-operacoes orange btn-desativado"
        : "btn-operacoes orange";
    },
    classBotaoEditar() {
      return this.itemsSelecionados.length < 2
        ? "desabilitado btn-acao"
        : "btn-acao";
    },
    classBotaoExcluir() {
      return this.itemsSelecionados.length == 0
        ? "desabilitado btn-acao"
        : "btn-acao";
    },
  },
};
</script>
<style>
.botoes-acoes {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 260px;
}
.btn-editar {
  width: 49%;
}
.inputs-editar {
  justify-content: space-between;
  align-content: space-between;
  height: 100px;
}
.campos {
  min-width: 300px;
  max-width: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.desabilitado {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-acao {
  display: flex;
  align-items: center;
}
.icones-btn {
  width: 20px;
  margin: 3px;
}
</style>
