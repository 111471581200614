import ApiService from "@/common/api/api.service";

const FavorecidosService = {
  async listar_favorecidos() {
    let retorno = await ApiService.get(`favorecido`);

    let data = retorno.data.data.favorecidos;

    let retornoFormatado = [];

    for (let i = 0; i < data.length; i++) {
      retornoFormatado.push({
        codigo: data[i].codigo,
        nome: data[i].nome,
        banco: data[i].banco,
        ag_conta: data[i].agencia + "/" + data[i].conta,
        pix: data[i].pix,
        indicador: data[i].indicador,
      });
    }

    return retornoFormatado;
  },
};

export default FavorecidosService;
