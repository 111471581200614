
export class Cheques {
  constructor() {
    this.id = "";
    this.nomeArquivo = { nomeArquivo: "", tipo: "TEXT" };
    this.titulos = "";
    this.idItem = "";
    this.existing = "";
    this.cpfCnpjSacado = { cpfCnpjSacado: "", tipo: "CNPJ", editavel: true, obrigatorio: true };
    this.numeroTitulo = { numeroTitulo: "", tipo: "TEXT", editavel: true, obrigatorio: true };
    this.nomeSacado = { nomeSacado: "", tipo: "TEXT", editavel: true, obrigatorio: true };
    this.telefoneSacado = { tipo: "PHONE", editavel: true, telefoneSacado: "", obrigatorio: false };
    this.dataEmissao = { dataEmissao: "", tipo: "DATE", editavel: true, obrigatorio: true };
    this.dataVencimento = { dataVencimento: "", tipo: "DATE", editavel: true, obrigatorio: true };
    this.valorNominal = { valorNominal: null, tipo: "VALOR", editavel: true, obrigatorio: true };
    this.cmc7 = { cmc7: "", tipo: "TEXT", editavel: true, obrigatorio: true };
    this.endereco = { endereco: "", tipo: "TEXT", editavel: true, obrigatorio: false };
    this.bairro = { bairro: "", tipo: "TEXT", editavel: true, obrigatorio: false };
    this.cidade = { cidade: "", tipo: "TEXT", editavel: true, obrigatorio: false };
    this.uf = { uf: "", tipo: "TEXT", editavel: true, obrigatorio: false };
    this.cep = { cep: "", tipo: "CEP", editavel: true, obrigatorio: false };
    this.email = { email: "", tipo: "TEXT", editavel: true, obrigatorio: false };
    this.numeroEndereco = { numero: "0", tipo: "TEXT", editavel: true, numeroEndereco: "", obrigatorio: false };
    this.tipoTitulo = { tipoTitulo: "CTe", editavel: true, tipo: "SELECT", obrigatorio: true };
    this.new = true;
    this.editavel = true;
    this.habilitarSalvar = false

   
  }
}
