<template>
  <div>
    <b-dropdown v-if="listaAcao.length > 0" id="actions-dropdown" text="Ações" class="m-md-2" variant="primary">
      <b-dropdown-item v-for="item in listaAcao" :key="item.titulo" v-on:click="item.acao">{{ item.titulo }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "Acoes",
  components: {},
  props: {
    acao: { type: Array },
  },
  data() {
    return {};
  },
  computed: {
    listaAcao() {
      return this.acao.filter((item) => item.habilitar);
    },
  },

  methods: {},
};
</script>
