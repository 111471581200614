<template>
  <div class="operacoes-container">
    <div class="row">
      <div
        :class="[
          { 'col-12 col-md-9 operacoes': hasComunicados && !selected },
          { 'col-12 operacoes': !hasComunicados || selected },
          selected,
        ]"
      >
        <div class="container-steps">
          <div
            v-for="step in steps"
            :key="step.id"
            class="steps"
            v-on:click="
              () => {
                if (step.title == 'ESCOLHER_OPERACAO') {
                  selected = undefined;
                  backNewOperations();
                }
              }
            "
          >
            <div class="step-desktop">
              <div
                :class="
                  'step' +
                  (selected && step.id <= $store.getters.step ? ' active-step' : '')
                "
              >
                {{ step.id }}
              </div>
              <div
                :class="
                  'step-title ' +
                  (selected && step.id <= $store.getters.step ? ' active-step-text' : '')
                "
              >
                {{ $t(`OPERACOES.${step.title}`) }}
              </div>
            </div>

            <div class="step-mobile">
              <div
                :class="
                  'step' +
                  (selected && step.id <= $store.getters.step ? ' active-step' : '')
                "
              >
                {{ step.id }}
                <div
                  :class="
                    'step-title ' +
                    (selected && step.id <= $store.getters.step
                      ? ' active-step-text'
                      : '')
                  "
                >
                  {{ $t(`OPERACOES.${step.title}`) }}
                </div>
              </div>
            </div>
            <div v-if="step.id < 2" class="line-step"></div>
          </div>
        </div>

        <div v-if="!selected" class="container-operacoes">
          <div class="botoes-operacoes">
            <div class="operacoes-list-cards res-inputs">
              <div style="text-align: center">
                <div class="operacoes-title">{{ $t("OPERACOES.TIPO") }}</div>
                <div class="operacoes-desc">{{ $t("OPERACOES.TIPO_DESC") }}</div>

                <div class="res-inputs lista-botoes">
                  <div
                    v-for="card in cards"
                    :key="card.id"
                    v-on:click="selecionarOperacao(card)"
                  >
                    <div class="operacoes-card" v-if="card.enabled">
                      <div class="operacoes-card-icon">
                        <img height="24" :src="card.icon" />
                      </div>
                      <div class="operacoes-card-text">
                        <div class="operacoes-card-title">
                          {{ $t(`OPERACOES.${card.title}`) }}
                        </div>
                        <div class="operacoes-card-desc">
                          {{ $t(`OPERACOES.${card.desc}`) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="titulos_notas_content" v-if="selected == 'titulos_notas'">
          <TitulosNotas />
        </div>
        <div class="cnab" v-else-if="selected == 'cnab'">
          <Cnab />
        </div>
        <div class="cheques" v-else-if="selected == 'cheque'">
          <Cheque />
        </div>
        <div class="notas-promissorias" v-else-if="selected == 'notas_promissorias'">
          <NotaPromissoria />
        </div>

        <div @click="scrollToView" v-if="!selected" class="history-button">
          <feather type="arrow-down"></feather>
          <div>Ir para Histórico de operações</div>
        </div>
      </div>

      <div
        :class="[
          { 'col-3': hasComunicados && !select },
          { 'd-none': !hasComunicados || selected },
        ]"
      >
        <div class="card-mensageria">
          <mensageria
            @hasComunicados="verificarComunicados"
            @carregou="carregouComunicacao"
          />
        </div>
      </div>
    </div>

    <div v-if="!selected" class="operacoes-table" id="operacoes-table">
      <b-col cols="12">
        <b-card no-body class="mb-4">
          <div class="header-table-operacoes">
            <b-form-group
              label="Por Pagina"
              label-for="per-page-select"
              label-class="fw-medium"
              class="por-pagina"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                @change="setPorPagina"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
            <b-pagination
              @change="setPage"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
            ></b-pagination>
          </div>
          <b-table class="table-scroll" :items="items" :fields="campos" responsive>
            <template v-slot:cell(status)="item">
              <div :class="'badge-table ' + item.item.status.toLowerCase()">
                {{ item.item.status }}
              </div>
            </template>
            <template #cell(_)="item">
              <b-button
                @click="reenviarCancelado(item.item.bordero)"
                variant="default"
                class="border btn btn-sm"
                pill
              >
                Enviar Novamente
                <i class="fas fa-reply-all pl-2"></i>
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";
import { DEFINIR_STEP } from "@/store/operacoes.module";
import { OperacaoCancelada } from "@/common/models/OperacaoCancelada";
import OperacoesServices from "@/common/services/operacoes/operacoes.service";
import helpers from "@/common/utils/helpers";

import TitulosNotas from "./components/TitulosNotas.vue";
import Cnab from "./components/Cnab.vue";
import Cheque from "./components/Cheques.vue";
import NotaPromissoria from "./components/NotasPromissorias.vue";
import Mensageria from "../mensageria/Index.vue";
import Swal from "sweetalert2";

export default {
  name: "Index",
  components: {
    Cnab,
    Cheque,
    TitulosNotas,
    Mensageria,
    NotaPromissoria
},

  props: {
    tipoAcesso: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selected: undefined,
      items: [],
      campos: [
        {
          key: "bordero",
          label: this.$t("CAMPOS_TABELA_OPERACOES.BORDDERO"),
          sortable: true,
        },
        {
          key: "data",
          label: this.$t("CAMPOS_TABELA_OPERACOES.DATA"),
          formatter: (value) => helpers.formatarDataBr(value),
          sortable: true,
        },
        {
          key: "totalFace",
          label: this.$t("CAMPOS_TABELA_OPERACOES.TOTAL_FACE"),
          formatter: (value) => helpers.formatarValor(value),
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          key: "_",
          label: "",
          tdClass: "text-right",
        },
      ],
      actions: [{ id: "1", action: null, text: "Excluir" }],
      options: [
        { id: "1", value: null, text: "Ordernar Por" },
        { id: "2", value: "last", text: "Mais Recente" },
        { id: "3", value: "older", text: "Mais Antigo" },
        { id: "4", value: "highest_value", text: "Maior Valor" },
        { id: "5", value: "lower_value", text: "Menor Valor" },
      ],

      cards: [
        {
          id: 1,
          icon: require("../../assets/scss/icons/xml.svg"),
          title: "TITULOS_NOTAS",
          desc: "TITULOS_NOTAS_DESC",
          section: "titulos_notas",
          enabled: helpers.validarAcesso("Titulos").visualizar,
        },
        {
          id: 2,
          icon: require("../../assets/scss/icons/import.svg"),
          title: "IMPORTAR_CNAB",
          desc: "IMPORTAR_CNAB_DESC",
          section: "cnab",
          enabled: helpers.validarAcesso("Cnab").visualizar,
        },
        {
          id: 3,
          icon: require("../../assets/scss/icons/check.svg"),
          title: "CHEQUES",
          desc: "CHEQUES_DESC",
          section: "cheque",
          enabled: helpers.validarAcesso("Cheques").visualizar,
        },
        {
          id: 4,
          icon: require("../../assets/scss/icons/check.svg"),
          title: "NOTAS_PROMISSORIAS",
          desc: "NOTAS_PROMISSORIAS_DESC",
          section: "notas_promissorias",
          enabled: helpers.validarAcesso("NotasPromissorias").visualizar,
        },
      ],
      steps: [
        {
          id: "1",
          active: "active",
          title: "ESCOLHER_OPERACAO",
        },
        {
          id: "2",
          title: "PREENCHER_DADOS",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      validField: [],
      pageOptions: [5, 10, 15, { value: 100, text: this.$t("GERAL.PAGINACAO") }],
      hasComunicados: false,
      isComunicacaoCarregado: false,
    };
  },
  methods: {
    verificarComunicados(value) {
      value && value.length > 0
        ? (this.hasComunicados = true)
        : (this.hasComunicados = false);
    },
    carregouComunicacao() {
      this.isComunicacaoCarregado = true;
    },
    selecionarOperacao(card) {
      this.selected = card.section;
      this.$store.dispatch(DEFINIR_STEP, 1);
    },
    setPage(e) {
      this.listarOperacoes(e);
    },
    setPorPagina(e) {
      this.listarOperacoes(undefined, e);
    },

    scrollToView() {
      var elmntToView = document.getElementById("operacoes-table");
      elmntToView.scrollIntoView();
    },
    listarOperacoes(paginaAtual, porPagina) {
      this.$store.dispatch(START_LOADING);

      OperacoesServices.listarOperacoesRemessa({
        NumeroDaPagina: (paginaAtual || this.currentPage) - 1,
        TamanhoDaPagina: porPagina || this.perPage,
      })
        .then((result) => {
          let itens = result.data.data.itens;
          console.log(itens);

          this.perPage = porPagina || this.perPage;
          this.currentPage = paginaAtual || this.currentPage;
          this.items = itens;
          this.totalRows = result.data.data.paginacao.totalDeElementos;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    backNewOperations() {
      this.$store.dispatch(DEFINIR_BREADCRUMB, [
        { titulo: this.$t("MENU.OPERACOES") },
        { titulo: this.$t("OPERACOES.NOVA_OPERACAO") },
      ]);
    },
    reenviarCancelado(item) {
      let bordero = { bordero: item };
      let form = new OperacaoCancelada(bordero);
      this.$store.dispatch(START_LOADING);
      OperacoesServices.reenviarCancelado(form)
        .then((result) => {
          if (result.data.data.ehCheque) {
            this.selecionarOperacao({
              section: "cheque",
            });
            return;
          }

          this.selecionarOperacao({
            section: "titulos_notas",
          });
        })
        .catch((err) => console.log(err))
        .finally(() => this.$store.dispatch(STOP_LOADING));
    },
  },

  mounted() {
    this.listarOperacoes();

    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.OPERACOES") },
      { titulo: this.$t("OPERACOES.NOVA_OPERACAO") },
    ]);

    if (this.tipoAcesso != null) {
      this.selecionarOperacao({
        section: this.tipoAcesso,
      });
    }
  },
  computed: {
    classStep() {
      let classRet = "";
      let step = this.$store.getters.step;
      if (step == 0) {
        classRet = "step";
      }

      // 'step' + (selected && step.title == 'ESCOLHER_OPERACAO' ? ' active-step' : '')

      return classRet;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name !== "Login" && this.selected) {
      Swal.fire({
        text:
          "Você tem certeza que deseja sair dessa tela? você pode perder todas as informações não salva",
        showDenyButton: true,
        confirmButtonText: "Sair",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */

        if (result.isConfirmed) {
          next();
        } else if (result.isDenied) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
};
</script>
<style>
.card-mensageria {
  background-color: rgb(255, 255, 255);
  height: 100%;
  border-radius: 4px;
}

.notas-promissorias {
  width: 100%;
  padding: 10px;

}
</style>
