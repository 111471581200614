<template>
  <div
    class="upload-box"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <img :src="upIcon" />
    <div class="uploat-text-content is-mobile">
      <strong>{{ tituloArquivo || $t("OPERACOES.ARRASTE") }}</strong>
      <div class="upload-desc">
        {{
          `${
            type != "vazio"
              ? $t(
                  type == "cnab"
                    ? "OPERACOES.FORMATO_CNAB"
                    : type == "cnab-xml"
                    ? "OPERACOES.FORMATO_CNAB_XML"
                    : "OPERACOES.FORMATO"
                )
              : types
          }`
        }}
      </div>
    </div>
    <div class="upload-divider" />
    <strong v-on:click="fileUp" class="uploa-btn">
      {{ selecioneArquivo || $t("OPERACOES.SELECIONAR") }}
    </strong>
    <input
      :accept="fileType"
      type="file"
      :key="this.type"
      :id="'fileup-input-' + this.type"
      @change="inputUpload"
      class="fileup-input"
      :multiple="fileType == '.xml' || varios"
      name=""
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import TitulosENotas from "@/common/services/titulos-notas/titulos-notas.service";
import Cnab from "@/common/services/cnab/cnab.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import Util from "../../views/operacoes/utils/helper";
export default {
  name: "Upload",
  props: {
    type: { type: String },
    tituloArquivo: { type: String },
    selecioneArquivo: { type: String },
    types: { type: String, default: "" },
    varios: { type: Boolean, default: false },
  },
  data() {
    return {
      upIcon: require("./upload.svg"),
    };
  },
  computed: {
    fileType() {
      let type;
      if (!this.type) type = ".xml";
      if (this.type == "cnab-xml") type = ".xml, .zip, .rar";
      if (this.type == "cnab") type = ".rem, .txt";
      if (this.type == "vazio") type = this.types;

      return type;
    },
  },
  methods: {
    inputUpload(event) {
      this.drop(event);
      if (this.type != "cnab") event.target.value = "";
    },
    fileUp() {
      // document.getElementById("fileup-input").accept = this.fileType;
      // document.getElementById("fileup-input").multiple =
      //   this.fileType == ".xml";
      document.getElementById("fileup-input-" + this.type).click();
    },
    drop(event) {
      event.preventDefault();

      let files = event.target.files || event.dataTransfer.files;
      if (!this.type) {
        this.$store.dispatch(START_LOADING);

        TitulosENotas.importarXml({}, files)
          .then((result) => {
            console.log(result);
            var tituloDuplicado = result.data.data.mensagem;
            if (tituloDuplicado.length > 0) {
              Swal.fire({
                title: this.$t("GERAL.ATENCAO"),
                text: tituloDuplicado,
                icon: "info",
                confirmButtonText: this.$t("GERAL.OK"),
              }).then(() => {});
            }
            Util.verifyData(Util.formatData(result));
            this.$emit("onLoad", result);
          })
          .catch(() => {})
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      } else if (this.type == "cnab") {
        if (files && files.length > 1) {
          Swal.fire({
            title: this.$t("GERAL.ATENCAO"),
            text: this.$t("GERAL.UM_ARQUIVO"),
            icon: "warning",
            confirmButtonText: this.$t("GERAL.OK"),
          }).then(() => {});

          return;
        }

        this.$store.dispatch(START_LOADING);

        Cnab.validarCnab(files)
          .then(() => {
            this.importarCnab(files);
            event.target.value = "";
          })
          .catch((err) => {
            let tabela = document.createElement("table");
            tabela.className = "table b-table";

            let erros = err.response.data.errors;
            let cabecalho = tabela.createTHead();
            cabecalho.className = "thead-light";

            if (erros.length > 0) {
              let erro = erros[0];
              let linha = cabecalho.insertRow();

              let dadosErro = erro.split(";");
              for (let j = 0; j < dadosErro.length; j++) {
                let coluna = linha.insertCell();
                coluna.outerHTML = `<th class="nowrap">${
                  dadosErro[j].split(": ")[0]
                }</th>`;
              }
            }

            for (let i = 0; i < erros.length; i++) {
              let erro = erros[i];
              let linha = tabela.insertRow();

              let dadosErro = erro.split(";");
              for (let j = 0; j < dadosErro.length; j++) {
                let coluna = linha.insertCell();
                coluna.innerHTML = dadosErro[j].split(": ")[1];
              }
            }
            let div = document.createElement("div");
            div.appendChild(tabela);
            let divTexto = document.createElement("div");
            divTexto.innerHTML = "Deseja prosseguir?";
            divTexto.className = "swall-texto-left-button";
            div.appendChild(divTexto);

            Swal.fire({
              title:
                "<i class='fas fa-exclamation-triangle mr-2 text-valorem-vermelho'></i><strong class='text-valorem-vermelho'>O arquivo gerou os seguintes alertas:</strong>",
              html: div,
              showDenyButton: true,
              showCloseButton: true,
              reverseButtons: true,
              confirmButtonText: "Sim",
              denyButtonText: "Não",
              customClass: {
                container: "swal-customizado swal-full swal-alertas",
                confirmButton: "btn btn-valorem-verde",
                cancelButton: "btn btn-outline-valorem-grafite",
                denyButton: "btn btn-outline-valorem-grafite mr-2",
                closeButton: "btn",
                icon: "icon-class",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.isConfirmed) this.importarCnab(files);
              event.target.value = "";
            });
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      } else if (this.type == "cnab-xml") {
        this.$store.dispatch(START_LOADING);

        Cnab.importarXmlCnab({}, files)
          .then((retorno) => {
            this.$emit("onLoadXml", retorno);
          })
          .catch(() => {})
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      } else if (this.type == "vazio") {
        for (let index = 0; index < files.length; index++) {
          let nome = files[index].name;
          let extensao = files[index].name.split(".").pop().replace(".", "");
          this.getBase64(files[index]).then((data) => {
            this.$emit("upload", {
              base64: data,
              nome: nome,
              extensao: extensao,
            });
          });
        }
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    dragover(event) {
      event.preventDefault();
    },
    dragleave(event) {
      event.preventDefault();
    },
    importarCnab(files) {
      Cnab.importarCnab({}, files).then((retorno) => {
        this.$emit("onLoad", retorno);
      });
    },
  },
};
</script>
