<template>
  <b-modal :ref="id" :id="id" :title="title" :size="size">
    <slot />
    <template #modal-footer="{}">
      <b-button v-if="habilitarConfirmar" size="lg" :class="classHabilitarConfirmar" :variant="variant" @click="() => funcaoConfirmar()">
        {{ TituloConfirmar }}
      </b-button>
      <b-button size="lg" @click="acaoVoltar">
        Voltar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "",
    },
    TituloConfirmar: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    habilitarConfirmar: {
      type: Boolean,
    },
    sairDoModal: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: "lg",
    },
  },
  methods: {
    acaoVoltar(){
      if(this.sairDoModal){
        return this.$bvModal.hide(this.id)
      }
      this.$emit("funcaoVoltar");
    },
    funcaoConfirmar(){
      this.$emit("funcaoConfirmar");
    }
  },
  computed: {
    classHabilitarConfirmar() {
      return !this.habilitarConfirmar ? "btn-operacoes orange btn-desativado" : "btn-operacoes orange";
    },
  },
};
</script>
