import ApiService from "@/common/api/api.service";
import helper from "@/common/utils/helpers.js";
// import i18n from "../../../i18n/i18n";
// import Vue from "vue";

const ChequesService = {
  formatarTitulo(retorno) {
    let retornoFormatado = [];
    let valorRetornado = retorno.data.data.titulos;

    for (let i = 0; i < valorRetornado.length; i++) {
      let ddd = valorRetornado[i].telefoneSacado.ddd.ddd;
      
      valorRetornado[i].idItem = valorRetornado[i].id;
      delete valorRetornado[i].id;

      valorRetornado[i].telefoneSacado = valorRetornado[i].telefoneSacado.numero;
      valorRetornado[i].telefoneSacado.telefoneSacado = (ddd || "") + "" + (valorRetornado[i].telefoneSacado.numero || "");
      delete valorRetornado[i].telefoneSacado.numero;

      let enderecoSacado = valorRetornado[i].enderecoSacado;
      enderecoSacado.numeroEndereco = enderecoSacado.numero;
      enderecoSacado.numeroEndereco.numeroEndereco = enderecoSacado.numero.numero;

      delete enderecoSacado.numeroEndereco.numero;
      delete valorRetornado[i].enderecoSacado;
      delete enderecoSacado.numero;
      valorRetornado[i].habilitarSalvar = helper.validarAcesso('Cheques').editar;

      retornoFormatado.push({ ...valorRetornado[i], ...enderecoSacado });
    }

    return retornoFormatado;
  },
  formatarEnvioTitulo(item) {
    let phone = undefined;
    let ddd = undefined;

    if (item && item["telefoneSacado"] && item["telefoneSacado"]["telefoneSacado"]) {
      let telefone = item["telefoneSacado"]["telefoneSacado"].replace(/[^0-9]/g, "");
      ddd = telefone.substring(0, 2);
      phone = telefone.substring(2);
    }

    let itemFromSave = {
      cpfCnpjSacado: item["cpfCnpjSacado"]["cpfCnpjSacado"] ? item["cpfCnpjSacado"]["cpfCnpjSacado"].replace(/\D/g, "") : undefined,
      nomeSacado: item["nomeSacado"]["nomeSacado"],
      enderecoSacado: {
        endereco: item["endereco"]["endereco"],
        numero: item["numeroEndereco"]["numeroEndereco"],
        bairro: item["bairro"]["bairro"],
        cidade: item["cidade"]["cidade"],
        uf: item["uf"]["uf"],
        cep: item["cep"]["cep"] ? parseInt(item["cep"]["cep"].toString().replace("-", "")) : undefined,
        email: item["email"]["email"] ? item["email"]["email"] : null,
      },
      telefoneSacado: {
        ddd: ddd == null || !ddd ? undefined : parseInt(ddd),
        numero: phone ? parseInt(phone.replace("-", "")) : null,
      },
      numeroTitulo: item["numeroTitulo"]["numeroTitulo"],
      cmc7: item["cmc7"]["cmc7"],
      dataEmissao: item["dataEmissao"]["dataEmissao"],
      dataVencimento: item["dataVencimento"]["dataVencimento"] != null ? item["dataVencimento"]["dataVencimento"] : undefined,
      valorNominal: item["valorNominal"]["valorNominal"] ? parseFloat(item["valorNominal"]["valorNominal"]) : "0",
      id: item.idItem ? item.idItem : undefined,
    };
    return itemFromSave;
  },
  async listar_titulos() {
    let retorno = await ApiService.get(`cheque`);

    let valorNominal = 0;

    let retornoFormatado = this.formatarTitulo(retorno);
    let verificarTituloDuplicado = [];
    let titulosDuplicados = false;

    for (let i = 0; i < retornoFormatado.length; i++) {
      valorNominal += parseFloat(retornoFormatado[i]["valorNominal"]["valorNominal"]);

      verificarTituloDuplicado.push(retornoFormatado[i]["numeroTitulo"]["numeroTitulo"]);
    }

    // let duplicados = verificarTituloDuplicado.filter(function(value, index, self) {
    //   return self.indexOf(value) !== index;
    // });

    // if (duplicados && duplicados.length > 0) {
    //   titulosDuplicados = true;
    //   Vue.$toast.error(i18n.t("OPERACOES.TITULOS_DUPLICADOS"), {
    //     position: "top-right",
    //     timeout: 4000,
    //     closeOnClick: true,
    //     pauseOnFocusLoss: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     draggablePercent: 1,
    //     showCloseButtonOnHover: false,
    //     hideProgressBar: false,
    //     closeButton: "button",
    //     icon: true,
    //     rtl: false,
    //   });
    // }

    let retornoService = {
      retornoFormatado,
      valorNominal,
      titulosDuplicados,
    };

    return retornoService;
  },
  enviarOperacao(form, file) {
    return ApiService.post(`cheque/enviar`, { ...form }, {}, file);
  },
  limparCache(form, file) {
    return ApiService.post(`cheque/limpar-cache`, { ...form }, {}, file);
  },
  excluir(id) {
    return ApiService.delete(`cheque/?id=${id}`);
  },
  excluirVarios(id) {
    return ApiService.delete(`cheque/remover-varios/?${id}`);
  },
  salvar(form) {
    let resultado = [];
    let envioFormatado = this.formatarEnvioTitulo(form);

    if (!envioFormatado.id) resultado = ApiService.post(`cheque/`, { ...envioFormatado });
    else resultado = ApiService.put(`cheque/`, { ...envioFormatado });
    return resultado;
  },
};

export default ChequesService;
